import { LegacyAny } from '@soracom/shared/core';

import { FormGroup } from '@angular/forms';
import {
  EventRuleOptions,
  eventRuleValueMapping,
  EventTargetOptionIds,
} from '../edit-event-handler/edit-event-handler.component';
import { Actions } from '../edit-event-new-action/edit-event-new-action.component';

export interface EventConfig {
  // When event list retrieved
  handlerId?: string;

  // Target
  targetImsi?: string;
  targetGroupId?: string;
  targetTag?: string;
  targetOperatorId?: string;

  // name & description
  name: string;
  description: string;

  // rule config
  ruleConfig: {
    type: string;
    properties: {
      [key: string]: string | number | boolean;
    };
  };

  // actions
  actionConfigList: ActionConfigList[];

  // status
  status: 'active' | 'inactive';
}

export interface ActionConfigList {
  type: string;
  properties: KeyValue<string>;
}

export interface KeyValue<T> {
  [key: string]: T;
}

export interface EventHandlerFormValue {
  eventName: string;
  description: string;
  targetValue: string;
  isActive: boolean;
  eventTarget: string;
  eventRule: {
    rule: string;
    inactiveTimeoutOffsetMinutes: string;
    inactiveTimeoutDateConst: string;
    ruleValue: string | number;
    hasImei?: boolean;
    runOnceAmongTarget: boolean;
    sourceStatus?: string;
  };
  actions: {
    [key: string]: KeyValue<string | number | KeyValue<string>>;
  };
}

export function createReversibleObject(obj: LegacyAny) {
  const reversibleObj: LegacyAny = {};

  Object.keys(obj).forEach((key) => {
    reversibleObj[(reversibleObj[key] = obj[key])] = key;
  });

  return reversibleObj;
}

export function convertPairValuesToControlValues(object: KeyValue<string>, baseName: string) {
  const values: LegacyAny = [];

  if (!object) {
    return undefined;
  }

  Object.keys(object).reduce((prevVal, key) => {
    if (key.match(`${baseName}Name`)) {
      prevVal.push({
        key: object[key],
        value: object[`${baseName}Value-${key.split('-')[1]}`],
      });
    }
    return prevVal;
  }, values);

  return values;
}

export function parseAPIDataToForm(apiData: EventConfig): EventHandlerFormValue {
  // Get which target is in apiData
  const eventTarget = Object.keys(EventTargetOptionIds).filter(
    (value: LegacyAny) => !!(apiData as LegacyAny)[value]
  )[0];
  const {
    ruleConfig: { type: ruleType, properties },
  } = apiData;

  const formValues: EventHandlerFormValue = {
    eventName: apiData.name,
    description: apiData.description,
    isActive: apiData.status === 'active',
    eventTarget,
    targetValue: (apiData as LegacyAny)[eventTarget],
    eventRule: {
      rule: ruleType,
      inactiveTimeoutDateConst: properties.inactiveTimeoutDateConst as string,
      inactiveTimeoutOffsetMinutes: properties.inactiveTimeoutOffsetMinutes as string,
      ruleValue: apiData.ruleConfig.properties[eventRuleValueMapping[ruleType]] as string,
      hasImei: typeof properties.hasImei === 'string' ? properties.hasImei === 'true' : !!properties.hasImei,
      runOnceAmongTarget:
        typeof properties.runOnceAmongTarget === 'string'
          ? properties.runOnceAmongTarget === 'true'
          : !!properties.runOnceAmongTarget,
      sourceStatus: apiData.ruleConfig.properties.sourceStatus as string,
    },
    actions: parseAPIActionsToForm(apiData),
  };

  return formValues;
}

function parseAPIActionsToForm(apiData: EventConfig): EventHandlerFormValue['actions'] {
  const { actionConfigList } = apiData;
  let formActions: EventHandlerFormValue['actions'] = {};
  const parseActionProps = (config: ActionConfigList) => {
    let props: KeyValue<string | KeyValue<string>> = {};

    switch (config.type) {
      case Actions.ExecuteWebRequestAction:
        props = {
          ...config.properties,
          ...(config.properties.headers && {
            headers: convertObjectToFormPairs(JSON.parse(config.properties.headers), 'header'),
          }),
        };
        break;
      case Actions.InvokeAWSLambdaAction:
        const { executionDateTimeConst, credentialsId, endpoint, functionName, accessKey, secretAccessKey, ...params } =
          config.properties;
        props = {
          executionDateTimeConst,
          endpoint,
          functionName,
          credentialsId,
          accessKey,
          secretAccessKey,
          ...(params && { params: convertObjectToFormPairs(params, 'param') }),
        };
        break;
      case Actions.SendMailAction:
      case Actions.SendMailToOperatorAction:
      case Actions.ChangeSpeedClassAction:
      case Actions.ActivationAction:
      case Actions.DeactivationAction:
      case Actions.StandbyAction:
      default:
        props = {
          ...config.properties,
        };
    }

    return props;
  };

  actionConfigList.forEach((config, index) => {
    formActions = {
      ...formActions,
      [`${config.type}-${index + 1}`]: parseActionProps(config),
    };
  });

  return formActions;
}

function convertObjectToFormPairs(object: LegacyAny, baseName: LegacyAny) {
  let pairs = {};
  // convert params to form style object
  Object.keys(object).forEach((key, index) => {
    pairs = {
      ...pairs,
      [`${baseName}Name-${index + 1}`]: key,
      [`${baseName}Value-${index + 1}`]: object[key],
    };
  });

  return pairs;
}

export function processFormAndGenerateRequestBody(eventHandlerForm: FormGroup, handlerId?: string) {
  const formValue = eventHandlerForm.value;
  const requestBody: EventConfig = {
    name: formValue.eventName,
    description: formValue.description,
    ...(handlerId && { handlerId }),
    [formValue.eventTarget]: formValue.targetValue,
    status: formValue.isActive ? 'active' : 'inactive',
    ruleConfig: getEventRuleConfig(eventHandlerForm),
    actionConfigList: getEventActionConfigList(eventHandlerForm),
  };

  return requestBody;
}

function getEventRuleConfig(eventHandlerForm: FormGroup) {
  const formValue = eventHandlerForm.value;
  const { rule, ruleValue, ...otherProps } = formValue.eventRule;
  const type = rule;
  let properties = {
    ...otherProps,
  };

  switch (rule) {
    case EventRuleOptions.SubscriberDailyTrafficRule:
    case EventRuleOptions.SubscriberMonthlyTrafficRule:
    case EventRuleOptions.SubscriberCumulativeTrafficRule:
    case EventRuleOptions.SimDailyTotalTrafficRule:
    case EventRuleOptions.SimMonthlyTotalTrafficRule:
    case EventRuleOptions.SimCumulativeTotalTrafficRule:
    case EventRuleOptions.DailyTotalTrafficRule:
    case EventRuleOptions.MonthlyTotalTrafficRule:
    case EventRuleOptions.DailyTrafficRule:
    case EventRuleOptions.MonthlyTrafficRule:
    case EventRuleOptions.CumulativeTrafficRule:
      properties = {
        ...properties,
        [eventRuleValueMapping[rule]]: Number(ruleValue),
      };
      break;
    case EventRuleOptions.SubscriberStatusAttributeRule:
    case EventRuleOptions.SubscriberSpeedClassAttributeRule:
    case EventRuleOptions.SubscriberSessionStatusRule:
    case EventRuleOptions.SimStatusAttributeRule:
    case EventRuleOptions.SimSpeedClassAttributeRule:
    case EventRuleOptions.SimSessionStatusRule:
    case EventRuleOptions.SimSubscriptionStatusRule:
    case EventRuleOptions.MonthlyChargeRule:
    case EventRuleOptions.SessionStatusRule:
      properties = {
        ...properties,
        [eventRuleValueMapping[rule]]: ruleValue,
      };
      break;
    case EventRuleOptions.SubscriberFirstTrafficRule:
    case EventRuleOptions.SubscriberExpiredRule:
    case EventRuleOptions.SimExpiredRule:
    case EventRuleOptions.DeviceRegisteredRule:
    default:
      break;
  }

  return {
    type,
    properties,
  };
}

function convertFormKeyValueInputToObject(keyValueInputs: LegacyAny, baseName: string) {
  let object = {};
  // convert header props to json
  Object.keys(keyValueInputs).forEach((key) => {
    if (key.match(`${baseName}Name-*`) && keyValueInputs[key]) {
      object = {
        ...object,
        [keyValueInputs[key]]: keyValueInputs[`${baseName}Value-${key.split('-')[1]}`],
      };
    }
  });

  return object;
}

function getEventActionConfigProps(action: LegacyAny, formProps: LegacyAny) {
  let props = {
    ...formProps,
  };

  switch (action) {
    case Actions.ExecuteWebRequestAction:
      props = {
        ...props,
        headers: JSON.stringify(convertFormKeyValueInputToObject(props.headers, 'header')),
      };
      return props;
    case Actions.InvokeAWSLambdaAction:
      props = {
        ...props,
        ...convertFormKeyValueInputToObject(props.params, 'param'),
      };
      delete props.params;
      return props;
    default:
      return props;
  }
}

function getEventActionConfigList(eventHandlerForm: FormGroup) {
  const formValue = eventHandlerForm.value;
  const formActions = formValue.actions;

  if (!formActions) {
    return [];
  }

  const actions = Object.keys(formActions).map((actionKey) => {
    const type = actionKey.split('-')[0];
    const properties = getEventActionConfigProps(type, formActions[actionKey]);

    return {
      type,
      properties,
    };
  });

  return actions;
}
