<div class="ds-cols key-value-pairs-{{ componentIndex }}">
  <div class="ds-span--4">
    <ui-input [content]="keyInput"></ui-input>
  </div>
  <div class="ds-span--7">
    <ui-input [content]="valueInput"></ui-input>
  </div>
  <div class="ds-span--1">
    <ui-button *ngIf="!addPairButtonAdded" [content]="addPairButton"></ui-button>
    <ui-button *ngIf="addPairButtonAdded" [content]="removePairButton"></ui-button>
  </div>
</div>
