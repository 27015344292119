import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JsonEditorComponent, JsonEditorOptions } from '@soracom/forks/ang-jsoneditor';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import {
  EventRuleOptions,
  eventRuleValueMapping,
  getRulesSpeedClassAttributeOptionsIds,
  RuleReEvaluateOptions,
  sessionStatusOptionsIds,
  simSubscriptionStatusOptionsIds,
  subscriberStatusAttributeOptionIds,
} from '../edit-event-handler/edit-event-handler.component';
import {
  Actions,
  getSpeedClassOptionIds,
  RunPriorityOptions,
} from '../edit-event-new-action/edit-event-new-action.component';
import { schema } from './event-handler-schema';
import { ModalAppStateAttributeTracker } from '@ts/app-body-attribute-utils';

const eventHandlerTemplateUniqueKey = 'event-handler-template-modal';

const subscriberRules = Object.keys(EventRuleOptions)
  .filter((key) => /^Subscriber.*$/.test(key))
  .concat([EventRuleOptions.DeviceRegisteredRule]);

const simRules = Object.keys(EventRuleOptions)
  .filter((key) => /^Sim.*$/.test(key))
  .concat([EventRuleOptions.DeviceRegisteredRule]);

const groupRules = [
  ...subscriberRules,
  ...simRules,
  EventRuleOptions.DailyTotalTrafficRule,
  EventRuleOptions.MonthlyTotalTrafficRule,
];

const operatorRules = [
  ...groupRules,
  EventRuleOptions.DailyTotalTrafficRule,
  EventRuleOptions.MonthlyTotalTrafficRule,
  EventRuleOptions.MonthlyChargeRule,
];

const actionProps: LegacyAny = {
  [Actions.SendMailAction]: ['to', 'title', 'message', 'executionDateTimeConst'],
  [Actions.ChangeSpeedClassAction]: ['speedClass', 'executionDateTimeConst'],
  [Actions.SendMailToOperatorAction]: ['title', 'message', 'executionDateTimeConst'],
  [Actions.ActivationAction]: ['executionDateTimeConst'],
  [Actions.DeactivationAction]: ['executionDateTimeConst'],
  [Actions.StandbyAction]: ['executionDateTimeConst'],
  [Actions.ExecuteWebRequestAction]: ['url', 'httpMethod', 'contentType', 'executionDateTimeConst'],
  [Actions.InvokeAWSLambdaAction]: ['endpoint', 'functionName', 'credentialsId', 'executionDateTimeConst'],
  [Actions.ImeiLockAction]: ['executionDateTimeConst'],
};

function isRuleValueOptional(givenRule: EventRuleOptions) {
  const notRequiredValueRules = [
    EventRuleOptions.SimStatusAttributeRule,
    EventRuleOptions.SimSpeedClassAttributeRule,
    EventRuleOptions.SubscriberStatusAttributeRule,
    EventRuleOptions.SubscriberSpeedClassAttributeRule,
    EventRuleOptions.SimSubscriptionStatusRule,
  ];

  return notRequiredValueRules.includes(givenRule);
}

@Component({
  selector: 'app-event-handler-template',
  templateUrl: './event-handler-template.component.html',
  styleUrls: ['./event-handler-template.component.scss'],
})
export class EventHandlerTemplateComponent implements OnInit, OnDestroy {
  // @ts-expect-error (legacy code incremental fix)
  @Input() onDialogClose: () => void;

  editorOptions = new JsonEditorOptions();
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('editor', { static: false }) editor: JsonEditorComponent;

  templateData: LegacyAny;
  isJsonDataValid = true;
  // @ts-expect-error (legacy code incremental fix)
  footerButtonBar: UiButtonBar;
  apiAlertManager = new AlertsManager();
  isUpdating = false;
  // @ts-expect-error (legacy code incremental fix)
  fileName: string;

  constructor(
    public soracomApiService: SoracomApiService,
    private translate: TranslateService,
    private featureVisibilityService: FeatureVisibilityService,
  ) {}

  ngOnInit(): void {
    //for sds compatibility
    ModalAppStateAttributeTracker.addModalAppState(eventHandlerTemplateUniqueKey);
    this.footerButtonBar = UiButtonBar.configure((bar) => {
      bar.leftButtons = [
        UiButton.configure((button) => {
          button.titleId = 'eventHandlerTemplate.button.cancel';
          button.onClick = this.cancel;
        }),
        UiButton.configure((button) => {
          button.titleId = 'eventHandlerTemplate.button.create';
          button.onClick = this.createEvent;
          button.isDisabled_ƒ = () => this.isJsonDataValid;
        }),
      ];
    });
    this.editorOptions.mode = 'code';
    this.editorOptions.modes = ['code', 'tree'];
    this.editorOptions.schema = schema;
    this.editorOptions.enableTransform = false;
    this.editorOptions.enableSort = false;
    this.editorOptions.onValidationError = this.onJsonEditorValidationError;
    // @ts-expect-error (legacy code incremental fix)
    this.editorOptions.onValidate = this.onJsonEditorValidate;
  }

  ngOnDestroy(): void {
    ModalAppStateAttributeTracker.removeModalAppState(eventHandlerTemplateUniqueKey);
  }

  onJsonEditorValidationError = (errors: LegacyAny) => {
    this.isJsonDataValid = errors?.length > 0 ? true : false;
  };

  onJsonEditorValidate = (json: any) => {
    const errors = [];

    errors.push(...this.ruleConfigValidation(json));

    if (json?.status && !['active', 'inactive'].includes(json.status)) {
      errors.push({ path: ['status'], message: this.translateValidationMessage('invalidStatus') });
    }

    errors.push(...this.actionConfigValidation(json));

    return errors.length ? errors : null;
  };

  ruleConfigValidation = (json: LegacyAny) => {
    const errors: LegacyAny = [];

    if (
      !json?.hasOwnProperty('targetImsi') &&
      !json.hasOwnProperty('targetSimId') &&
      !json.hasOwnProperty('targetGroupId') &&
      !json.hasOwnProperty('targetOperatorId')
    ) {
      errors.push({ path: [], message: this.translateValidationMessage('targetMissing') });
      return errors;
    }

    if (json.hasOwnProperty('targetImsi') && !json.targetImsi) {
      errors.push({ path: ['targetImsi'], message: this.translateValidationMessage('targetImsiValueRequired') });
      return errors;
    } else if (json.hasOwnProperty('targetSimId') && !json.targetSimId) {
      errors.push({ path: ['targetSimId'], message: this.translateValidationMessage('targetSimIdValueRequired') });
      return errors;
    } else if (json.hasOwnProperty('targetGroupId') && !json.targetGroupId) {
      errors.push({ path: ['targetGroupId'], message: this.translateValidationMessage('targetGroupIdValueRequired') });
      return errors;
    } else if (json.hasOwnProperty('targetOperatorId') && !json.targetOperatorId) {
      errors.push({
        path: ['targetOperatorId'],
        message: this.translateValidationMessage('targetOperatorIdValueRequired'),
      });
      return errors;
    }

    if (!json.ruleConfig || !json.ruleConfig?.hasOwnProperty('type')) {
      return errors; // required check is already done by schema
    }

    if (json.targetImsi && !subscriberRules.includes(json.ruleConfig.type)) {
      errors.push({
        path: ['ruleConfig', 'type'],
        message: this.translateValidationMessage('invalidRuleTypeTargetImsi'),
      });
      return errors;
    } else if (json.targetSimId && !simRules.includes(json.ruleConfig.type)) {
      errors.push({
        path: ['ruleConfig', 'type'],
        message: this.translateValidationMessage('invalidRuleTypeTargetSim'),
      });
      return errors;
    } else if (json.targetGroupId && !groupRules.includes(json.ruleConfig.type)) {
      errors.push({
        path: ['ruleConfig', 'type'],
        message: this.translateValidationMessage('invalidRuleTypeTargetGroup'),
      });
      return errors;
    } else if (json.targetOperatorId && !operatorRules.includes(json.ruleConfig.type)) {
      errors.push({
        path: ['ruleConfig', 'type'],
        message: this.translateValidationMessage('invalidRuleTypeTargetOperator'),
      });
      return errors;
    }

    if (!json.ruleConfig.properties) {
      errors.push({ path: ['ruleConfig'], message: this.translateValidationMessage('ruleConfigPropsRequired') });
      return errors;
    }

    if (
      eventRuleValueMapping[json.ruleConfig.type] &&
      !isRuleValueOptional(json.ruleConfig.type) &&
      !json.ruleConfig.properties.hasOwnProperty(eventRuleValueMapping[json.ruleConfig.type])
    ) {
      errors.push({
        path: ['ruleConfig', 'properties'],
        message: this.translateValidationMessage('ruleConfigPropRequired', {
          prop: eventRuleValueMapping[json.ruleConfig.type],
        }),
      });
      return errors;
    }

    if (
      (json.ruleConfig.properties.hasOwnProperty('targetStatus') && !json.ruleConfig.properties.targetStatus) ||
      (json.ruleConfig.properties.targetStatus &&
        !subscriberStatusAttributeOptionIds.includes(json.ruleConfig.properties.targetStatus))
    ) {
      errors.push({ path: ['ruleConfig'], message: this.translateValidationMessage('targetStatusInvalid') });
      return errors;
    }

    if (
      (json.ruleConfig.properties.hasOwnProperty('targetSpeedClass') && !json.ruleConfig.properties.targetSpeedClass) ||
      (json?.ruleConfig.properties.targetSpeedClass &&
        !getRulesSpeedClassAttributeOptionsIds(this.featureVisibilityService).includes(
          json.ruleConfig.properties.targetSpeedClass,
        ))
    ) {
      errors.push({ path: ['ruleConfig'], message: this.translateValidationMessage('targetSpeedClassInvalid') });
      return errors;
    }

    if (
      (json.ruleConfig.properties.hasOwnProperty('targetSessionStatus') &&
        !json.ruleConfig.properties.targetSessionStatus) ||
      (json.ruleConfig.properties.targetSessionStatus &&
        !sessionStatusOptionsIds.includes(json.ruleConfig.properties.targetSessionStatus))
    ) {
      errors.push({ path: ['ruleConfig'], message: this.translateValidationMessage('targetSessionStatusInvalid') });
      return errors;
    }

    if (
      (json.ruleConfig.properties.hasOwnProperty('targetOtaStatus') && !json.ruleConfig.properties.targetOtaStatus) ||
      (json.ruleConfig.properties.targetOtaStatus &&
        !simSubscriptionStatusOptionsIds.includes(json.ruleConfig.properties.targetOtaStatus))
    ) {
      errors.push({ path: ['ruleConfig'], message: this.translateValidationMessage('targetOtaStatusInvalid') });
      return errors;
    }

    if (
      (json.ruleConfig.properties.hasOwnProperty('inactiveTimeoutDateConst') &&
        !json.ruleConfig.properties.inactiveTimeoutDateConst) ||
      (json.ruleConfig.properties.inactiveTimeoutDateConst &&
        !Object.keys(RuleReEvaluateOptions).includes(json.ruleConfig.properties.inactiveTimeoutDateConst))
    ) {
      errors.push({
        path: ['ruleConfig'],
        message: this.translateValidationMessage('inactiveTimeoutDateConstInvalid'),
      });
      return errors;
    }

    return errors;
  };

  actionConfigValidation = (json: LegacyAny) => {
    const errors: LegacyAny = [];
    if (!json?.actionConfigList) {
      return errors;
    }

    if (json?.actionConfigList && !json.actionConfigList.length) {
      errors.push({ path: ['actionConfigList'], message: this.translateValidationMessage('inSufficientActions') });
      return errors;
    }

    json.actionConfigList.forEach((action: LegacyAny, index: LegacyAny) => {
      if (!action?.type) {
        errors.push({
          path: ['actionConfigList', index],
          message: this.translateValidationMessage('actionTypeMissing'),
        });
        return;
      }

      if (!Object.keys(Actions).includes(action.type)) {
        errors.push({
          path: ['actionConfigList', index, 'type'],
          message: this.translateValidationMessage('actionTypeInvalid'),
        });
        return;
      }

      if (!action.properties) {
        errors.push({
          path: ['actionConfigList', index],
          message: this.translateValidationMessage('missingActionConfigProps'),
        });
        return;
      }

      if (action.properties) {
        actionProps[action.type].forEach((requiredProp: LegacyAny) => {
          if (!action.properties.hasOwnProperty(requiredProp)) {
            errors.push({
              path: ['actionConfigList', index, 'properties'],
              message: this.translateValidationMessage('missingActionRequiredProp', { requiredProp }),
            });
            return;
          }
        });

        if (
          action.properties.hasOwnProperty('speedClass') &&
          !getSpeedClassOptionIds(this.featureVisibilityService).includes(action.properties.speedClass)
        ) {
          errors.push({
            path: ['actionConfigList', index, 'properties', 'speedClass'],
            message: this.translateValidationMessage('invalidSpeedClass'),
          });
          return;
        }

        if (
          action.properties.hasOwnProperty('executionDateTimeConst') &&
          !Object.keys(RunPriorityOptions).includes(action.properties.executionDateTimeConst)
        ) {
          errors.push({
            path: ['actionConfigList', index, 'properties', 'executionDateTimeConst'],
            message: this.translateValidationMessage('invalidExecutionDateTimeConst'),
          });
          return;
        }

        if (
          action.properties.hasOwnProperty('httpMethod') &&
          ['GET', 'DELETE'].includes(action.properties.httpMethod) &&
          action.properties.hasOwnProperty('body')
        ) {
          errors.push({
            path: ['actionConfigList', index, 'properties', 'body'],
            message: this.translateValidationMessage('bodyNotSupported'),
          });
          return;
        }
      }
    });

    return errors;
  };

  translateValidationMessage(text: string, params?: { [key: string]: string | number }) {
    return this.translate.instant(`eventHandlerTemplate.jsonValidationMessage.${text}`, params);
  }

  onFileUpload = (files: FileList) => {
    this.apiAlertManager.clear();
    const file = files[0];

    if (file.type !== 'application/json') {
      this.apiAlertManager.add(Alert.danger(this.translate.instant('eventHandlerTemplate.message.invalidFileType')));
      return;
    }

    this.fileName = file.name;
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = this.onFileReaderLoad;
    reader.onerror = this.onFileReaderLoad;
  };

  onFileReaderLoad = (event: LegacyAny) => {
    this.templateData = JSON.parse(event.target.result);
  };

  onFileReaderLoadError = (fr: FileReader, ev: ProgressEvent<FileReader>) => {
    this.apiAlertManager.clear();
    this.apiAlertManager.add(Alert.danger(fr.error));
  };

  cancel = () => {
    this.onDialogClose();
  };

  createEvent = async () => {
    this.isUpdating = true;
    this.apiAlertManager.clear();
    try {
      const response = await this.soracomApiService.createEventHandler(this.editor.get());
      if (response.status === 200 || response.status === 201) {
        this.apiAlertManager.add(Alert.success('eventHandlerTemplate.message.createSuccess'));
        setTimeout(() => {
          this.cancel();
        }, 1500);
      }
    } catch (e) {
      this.apiAlertManager.add(Alert.fromApiError(e, 'eventHandlerTemplate.message.commonError'));
    } finally {
      this.isUpdating = false;
    }
  };
}
