const ruleConfigSchema = {
  title: 'Rule config',
  type: 'object',
  properties: {
    type: {
      type: 'string',
    },
    properties: {
      type: 'object',
      properties: {
        inactiveTimeoutDateConst: {
          type: 'string',
        },
        limitTotalTrafficMegaByte: {
          type: 'number',
        },
        targetStatus: {
          type: 'string',
        },
        inactiveTimeoutOffsetMinutes: {
          type: 'number',
        },
        isRunOnceAmongTarget: {
          type: 'boolean',
        },
        hasImei: {
          type: 'boolean',
        },
      },
    },
  },
  required: ['type'],
};

const actionConfigSchema = {
  title: 'Action config list',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      type: {
        type: 'string',
      },
      properties: {
        type: 'object',
      },
    },
  },
};

export const schema = {
  title: 'Eventhandler config',
  properties: {
    targetOperatorId: {
      type: 'string',
    },
    targetGroupId: {
      type: 'string',
    },
    targetSimId: {
      type: 'string',
    },
    targetImsi: {
      type: 'string',
    },
    name: {
      title: 'Name',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    status: {
      title: 'Status',
      type: 'string',
    },
    ruleConfig: ruleConfigSchema,
    actionConfigList: actionConfigSchema,
  },
  required: ['name', 'ruleConfig', 'actionConfigList', 'status'],
};
