<app-user-notifications />

<main class="ds-app_main-content ds-rows --indent-small">
  <h2>{{ 'editEvent.title' | translate }}</h2>
  <div class="edit-event-handler-container">
    <div class="ds-modal--loading-refresh" *ngIf="isUpdating || isFetching"></div>
    <app-alerts [alertsManager]="formAlertManager"></app-alerts>
    <form class="ds-rows" [hidden]="isFetching" [formGroup]="eventHandlerForm" #eventHandlerFormRef>
      <ui-input [content]="eventName"></ui-input>
      <ui-input [content]="description"></ui-input>

      <div class="ds-group --flow-down">
        <div class="ds-group__title">
          {{ 'editEvent.sectionTitle.target' | translate }}
        </div>
        <div class="ds-cols">
          <div class="ds-span--4">
            <ui-select [content]="eventTarget"></ui-select>
          </div>
          <div class="ds-span--8">
            <div class="ds-field">
              <app-sim-autocomplete
                *ngIf="
                  eventTarget &&
                  [EventTargetOptionIds.targetImsi, EventTargetOptionIds.targetSimId].includes(eventTarget.value)
                "
                data-testid="targetValue"
                [formControl]="targetValueControl"
                [valueKey]="getEventTargetValueKey()"
              >
              </app-sim-autocomplete>

              <app-group-autocomplete
                *ngIf="eventTarget && [EventTargetOptionIds.targetGroupId].includes(eventTarget.value)"
                data-testid="targetValue"
                [formControl]="targetValueControl"
                valueKey="groupId"
              >
              </app-group-autocomplete>

              <ui-input
                *ngIf="eventTarget && eventTarget.value === EventTargetOptionIds.targetOperatorId"
                [content]="targetValue"
              ></ui-input>

              <div *ngIf="targetValueControl.touched && targetValueControl.errors" class="ds-text --alert">
                <div *ngFor="let error of targetValueControl.errors | keyvalue" data-testid="validationError">
                  {{ targetValue.validationErrorMessageStringId + '.' + error.key | translate: error.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ds-group --flow-down" data-testid="eventRuleSection">
        <div class="ds-group__title">
          {{ 'editEvent.sectionTitle.rule' | translate }}
        </div>
        <app-alerts [alertsManager]="subscriberStatusAttributeErrorAlertManager"></app-alerts>
        <div class="ds-cols">
          <div class="ds-span--3">
            <div class="line-breaker"></div>
            <ui-select [content]="eventRule"></ui-select>
          </div>
          <div *ngIf="eventRule && shouldShowSourceStatus()" class="ds-span--2" data-testid="sourceStatus">
            <ui-select [content]="sourceStatus"></ui-select>
          </div>
          <div class="ds-span--3">
            <div class="line-breaker" *ngIf="!shouldShowSourceStatus()"></div>
            <ng-template #ruleValueContainerRef></ng-template>
          </div>
          <div class="ds-span--2">
            <ui-select [content]="reEvaluateSelect"></ui-select>
          </div>
          <div class="ds-span--2">
            <ui-input [content]="reEvaluateRuleOffsetTime"></ui-input>
          </div>
        </div>
        <label class="ds-checkbox" *ngIf="showHasImeiCheck">
          <input
            type="checkbox"
            name="hasImei"
            [formControl]="$any(editEventHandlerService.getControl(eventRuleParentControlName + '.hasImei'))"
          />
          <div>{{ 'editEvent.label.hasImei' | translate }}</div>
        </label>
        <label
          class="ds-checkbox"
          data-testid="run-once"
          *ngIf="eventTarget && eventTarget.value !== EventTargetOptionIds.targetImsi"
        >
          <input
            type="checkbox"
            [formControl]="$any(editEventHandlerService.getControl(eventRuleParentControlName + '.runOnceAmongTarget'))"
          />
          <div>{{ 'editEvent.label.isRunOnceAmongTarget' | translate }}</div>
        </label>
      </div>

      <div class="ds-group --flow-down ds-counter">
        <div class="ds-group__title">
          {{ 'editEvent.sectionTitle.action' | translate }}
        </div>

        <ng-template #newActionContainerRef></ng-template>
      </div>

      <div class="ds-cols--center">
        <ui-button [content]="addActionButton"></ui-button>
      </div>

      <div class="ds-group">
        <label class="ds-checkbox" data-testid="status">
          <input type="checkbox" [formControl]="isActiveControl" />
          <div>{{ 'editEvent.label.isActive' | translate }}</div>
        </label>
      </div>

      <app-alerts [alertsManager]="apiAlertManager"></app-alerts>

      <div class="ds-cols --end">
        <div>
          <ui-button [content]="cancelButton"></ui-button>
          <ui-button *ngIf="handlerId" [content]="deleteButton"></ui-button>
          <ui-button [content]="updateButton" *ngIf="handlerId"></ui-button>
          <ui-button [content]="createButton" *ngIf="!handlerId"></ui-button>
        </div>
      </div>
    </form>
  </div>
</main>
