<app-user-notifications />
<main class="ds-app__main-container x-event-list-container x-event_handler-page-root">
  <header class="ds-banner --simple --indent-small">
    <div>
      <h2>{{ 'eventHandlerList.title' | translate }}</h2>
      <div [innerHTML]="'eventHandlerList.description' | translate"></div>
    </div>
  </header>

  <section class="ds-controls">
    <ui-button-bar [content]="topButtonBar"></ui-button-bar>
  </section>

  <div class="ds-modal--loading-refresh" *ngIf="isUpdating || isFetching"></div>

  <app-alerts [alertsManager]="alertManager"></app-alerts>

  <section class="x-target-filters ds-controls">
    <div class="ds-button-bar">

      <p class="ds-text --label">{{ 'eventHandlerList.filterForm.description' | translate }}:</p>

      <label
        class="ds-tag --outline"
        [ngClass]="{ active: filterByTargetImsiFormControl.value }"
        data-testid="filterByTargetImsi"
      >
        <input type="checkbox" [formControl]="filterByTargetImsiFormControl" (change)="filterEvents()" />
        <span>{{ 'eventHandlerList.filterForm.targetType.imsi' | translate }}</span>
      </label>

      <label
        class="ds-tag --outline"
        [ngClass]="{ active: filterByTargetSimFormControl.value }"
        data-testid="filterByTargetSim"
      >
        <input type="checkbox" [formControl]="filterByTargetSimFormControl" (change)="filterEvents()" />
        <span>{{ 'eventHandlerList.filterForm.targetType.sim' | translate }}</span>
      </label>

      <label
        class="ds-tag --outline"
        [ngClass]="{ active: filterByTargetGroupFormControl.value }"
        data-testid="filterByTargetGroup"
      >
        <input type="checkbox" [formControl]="filterByTargetGroupFormControl" (change)="filterEvents()" />
        <span>{{ 'eventHandlerList.filterForm.targetType.group' | translate }}</span>
      </label>

      <label
        class="ds-tag --outline"
        [ngClass]="{ active: filterByTargetOperatorFormControl.value }"
        data-testid="filterByTargetOperator"
      >
        <input type="checkbox" [formControl]="filterByTargetOperatorFormControl" (change)="filterEvents()" />
        <span>{{ 'eventHandlerList.filterForm.targetType.operator' | translate }}</span>
      </label>
    </div>
  </section>

  <section class="ds-app__main-content ds-rows">
    <table class="x-event-list-table ds-datatable --striped --pin-first">
      <thead class="--sticky">
        <tr>
          <th class="--min"></th>
          <th class="--20-w">{{ 'eventHandlerList.tableHeader.eventName' | translate }}</th>
          <th class="--min">{{ 'eventHandlerList.tableHeader.status' | translate }}</th>
          <th>{{ 'eventHandlerList.tableHeader.target' | translate }}</th>
          <th>{{ 'eventHandlerList.tableHeader.rule' | translate }}</th>
          <th class="--30-w">{{ 'eventHandlerList.tableHeader.actions' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of eventList | keyvalue">
          <td>
            <div class="ds-checkbox">
              <input type="checkbox" (change)="onSelectEventChange($event, event.key)" />
            </div>
          </td>
          <td>
            <a [routerLink]="'/event_handler/edit_event/' + event.key" [queryParams]="getUrlParam()" queryParamsHandling="merge">{{ event.value.name }}</a>
          </td>
          <td>
            <i *ngIf="event.value.status === 'active'" class="ds-icon --icon-online --xsmall --success"></i>
            <i *ngIf="event.value.status === 'inactive'" class="ds-icon --icon-offline --xsmall --alert"></i>
          </td>
          <td>
            <strong>{{ 'editEvent.eventTarget.' + getTargetType(event.value) | translate }} </strong>
            <span *ngIf="getTargetType(event.value) !== 'targetOperatorId'">{{
              $any(event.value)[$any(getTargetType(event.value))]
            }}</span>
          </td>
          <td>
            <strong>{{ 'editEvent.eventRule.' + event.value.ruleConfig.type | translate }} </strong>
            <span>{{ getRuleValue(event.value) }}</span>
          </td>
          <td>
            <div class="ds-card --xsmall --30-w" *ngFor="let actionConfig of event.value.actionConfigList">
              <div class="action-type ds-text --small">
                <b>{{ 'editEventActions.action.' + actionConfig.type | translate }}</b>
              </div>
              <div class="action-value ds-text --xsmall --30-w">
                {{ getActionValue(actionConfig) }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</main>

<app-event-handler-template
    *ngIf="showImportTemplateModal"
    [onDialogClose]="closeTemplateDialog"
  ></app-event-handler-template>
