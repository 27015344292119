import { LegacyAny } from '@soracom/shared/core';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import {
  EventRuleOptions,
  eventRuleValueMapping,
  EventTargetOptionIds,
} from '../edit-event-handler/edit-event-handler.component';
import { Actions } from '../edit-event-new-action/edit-event-new-action.component';
import { EventConfig } from '../utils/data-processor';
import { EventConfigList, EventListCache } from '../utils/event-list-cache';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface ScLocationChangeEvent {
  path: string;
  search: { [key: string]: any };
}

const ruleSuffix = {
  [EventRuleOptions.DailyTrafficRule]: 'MiB',
  [EventRuleOptions.MonthlyTrafficRule]: 'MiB',
  [EventRuleOptions.CumulativeTrafficRule]: 'MiB',
  [EventRuleOptions.SubscriberDailyTrafficRule]: 'MiB',
  [EventRuleOptions.SubscriberMonthlyTrafficRule]: 'MiB',
  [EventRuleOptions.SubscriberCumulativeTrafficRule]: 'MiB',
  [EventRuleOptions.SimDailyTotalTrafficRule]: 'MiB',
  [EventRuleOptions.SimMonthlyTotalTrafficRule]: 'MiB',
  [EventRuleOptions.SimCumulativeTotalTrafficRule]: 'MiB',
  [EventRuleOptions.DailyTotalTrafficRule]: 'MiB',
  [EventRuleOptions.MonthlyTotalTrafficRule]: 'MiB',
};

@Component({
  selector: 'app-event-handler-list',
  templateUrl: './event-handler-list.component.html',
  styleUrls: ['./event-handler-list.component.scss'],
})
export class EventHandlerListComponent implements OnInit {
  /** This may come from other pages like group, sim management modal etc */
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;

  /** This will come from subscriber's page */
  // @ts-expect-error (legacy code incremental fix)
  imsi: string;

  /** This will come from subscriber's page */
  // @ts-expect-error (legacy code incremental fix)
  operator: boolean;

  /** Right now it will be undefined, it may come from subscriber page in future */
  // @ts-expect-error (legacy code incremental fix)
  simId: string;

  /** Button bar at top */
  topButtonBar = new UiButtonBar();

  /** To show alerts */
  alertManager = new AlertsManager();

  /** Instance of event list cache */
  eventListCache = EventListCache.instance;

  /**
   * Event list to be rendered, it may contain full list or filtered list
   * As currently API doesn't support filter feature, it will be peformed at front side
   */
  // @ts-expect-error (legacy code incremental fix)
  eventList: EventConfigList;

  /** Holds events selected by user */
  selectedEventCache = [];

  /** Status for when fetching events from api */
  isFetching = false;

  /** Status when updating/deleting events */
  isUpdating = false;

  /** Form which contains controls to filter/search list */
  // @ts-expect-error (legacy code incremental fix)
  filterFormGroup: FormGroup;

  /** console logger */
  logger: Logger;

  showImportTemplateModal = false;

  constructor(
    private soracomApiService: SoracomApiService,
    private modalService: UiDsModalService,
    private translationService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      this.groupId = params?.groupId;
      this.imsi = params?.imsi;
      this.operator = params?.operator;
      this.simId = params?.simId;
    });
    this.logger = LoggerService.shared();
  }

  ngOnInit() {
    this.setupUiComponents();
    this.getEventList();
    this.setupFilterForm();
  }

  setupUiComponents() {
    this.setupButtons();
  }

  setupButtons() {
    this.topButtonBar = UiButtonBar.configure((bar) => {
      bar.leftButtons = [
        UiButton.configure((button) => {
          button.titleId = 'eventHandlerList.button.createNew';
          button.onClick = this.createNewEvent;
          button.iconName = 'icon-plus';
          button.buttonStyle = 'primary';
        }),
        UiButton.configure((button) => {
          button.titleId = 'eventHandlerList.button.deleteEvents';
          button.onClick = this.deleteEvents;
          button.iconName = 'icon-delete';
          button.buttonStyle = 'danger';
          button.isDisabled_ƒ = () => {
            return !this.selectedEventCache.length;
          };
        }),
        UiButton.configure((button) => {
          button.titleId = 'eventHandlerList.button.importTemplate';
          button.onClick = () => (this.showImportTemplateModal = true);
        }),
      ];
    });
  }

  setupFilterForm() {
    this.filterFormGroup = new FormGroup({
      targetType: new FormGroup({
        targetImsi: new FormControl(!!this.imsi),
        targetSim: new FormControl(!!this.simId),
        targetGroup: new FormControl(!!this.groupId),
        targetOperator: new FormControl(!!this.operator),
      }),
    });
  }

  async getEventList() {
    this.eventListCache.clearList();
    this.isFetching = true;
    this.alertManager.clear();

    try {
      const response = await this.soracomApiService.getEventHandlers('');
      this.logger.debug(response);
      if (response.status === 200 || response.status === 201) {
        this.eventListCache.addToList(response.data);
        this.filterEvents();
      }
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'eventHandlerList.message.commonError'));
    } finally {
      this.isFetching = false;
    }
  }

  filterEvents() {
    const fullList = this.eventListCache.getList();
    const conditions: LegacyAny = [];

    if (this.imsi) {
      // @ts-expect-error (legacy code incremental fix)
      conditions.push((config) => config.targetImsi === this.imsi);
    } else if (this.filterByTargetImsiFormControl.value) {
      // @ts-expect-error (legacy code incremental fix)
      conditions.push((config) => !!config.targetImsi);
    }

    if (this.simId) {
      // @ts-expect-error (legacy code incremental fix)
      conditions.push((config) => config.targetSimId === this.simId);
    } else if (this.filterByTargetSimFormControl.value) {
      // @ts-expect-error (legacy code incremental fix)
      conditions.push((config) => !!config.targetSimId);
    }

    if (this.groupId) {
      // @ts-expect-error (legacy code incremental fix)
      conditions.push((config) => config.targetGroupId === this.groupId);
    } else if (this.filterByTargetGroupFormControl.value) {
      // @ts-expect-error (legacy code incremental fix)
      conditions.push((config) => !!config.targetGroupId);
    }

    if (this.operator || this.filterByTargetOperatorFormControl.value) {
      // @ts-expect-error (legacy code incremental fix)
      conditions.push((config) => !!config.targetOperatorId);
    }

    this.selectedEventCache = [];

    if (!conditions.length) {
      this.eventList = {
        ...fullList,
      };

      return;
    }

    this.eventList = this.filterWithComparator<EventConfigList, EventConfig>(fullList, (_1, config) => {
      return conditions.reduce((accum: LegacyAny, curVal: LegacyAny) => {
        return accum || curVal(config);
      }, !conditions.length);
    });
  }

  private filterWithComparator<T, O>(list: T, comparatorFn: (key: string, value: O) => boolean) {
    // @ts-expect-error (legacy code incremental fix)
    return Object.keys(list).reduce((accumulator, curVal) => {
      // @ts-expect-error (legacy code incremental fix)
      if (comparatorFn(curVal, list[curVal])) {
        return {
          ...accumulator,
          // @ts-expect-error (legacy code incremental fix)
          [curVal]: { ...list[curVal] },
        };
      }

      return accumulator;
    }, {});
  }

  getTargetType(event: LegacyAny) {
    return Object.keys(EventTargetOptionIds).find((target) => !!event[target]);
  }

  getRuleValue(event: LegacyAny) {
    const value = event.ruleConfig.properties[eventRuleValueMapping[event.ruleConfig.type]];

    // @ts-expect-error (legacy code incremental fix)
    return value ? `${value} ${ruleSuffix[event.ruleConfig.type] || ''}` : '';
  }

  getActionValue(actionConfig: LegacyAny) {
    switch (actionConfig.type) {
      case Actions.SendMailAction:
        return `${this.translationService.instant('editEventActions.actionValueComponentLabel.emailTo')}: ${
          actionConfig.properties.to
        }`;
      case Actions.SendMailToOperatorAction:
        return `${this.translationService.instant('editEventActions.actionValueComponentLabel.emailTitle')}: ${
          actionConfig.properties.title
        }`;
      case Actions.ChangeSpeedClassAction:
        return `${this.translationService.instant(
          `editEventActions.runPriority.${actionConfig.properties.executionDateTimeConst}`
        )} → ${actionConfig.properties.speedClass}`;
      case Actions.ExecuteWebRequestAction:
        return actionConfig.properties.url;
      case Actions.InvokeAWSLambdaAction:
        return actionConfig.properties.endpoint;
      case Actions.ActivationAction:
      case Actions.DeactivationAction:
      default:
        return '';
    }
  }

  get filterByTargetImsiFormControl() {
    // @ts-expect-error (legacy code incremental fix)
    return this.filterFormGroup.get('targetType').get('targetImsi') as FormControl;
  }
  get filterByTargetGroupFormControl() {
    // @ts-expect-error (legacy code incremental fix)
    return this.filterFormGroup.get('targetType').get('targetGroup') as FormControl;
  }
  get filterByTargetOperatorFormControl() {
    // @ts-expect-error (legacy code incremental fix)
    return this.filterFormGroup.get('targetType').get('targetOperator') as FormControl;
  }
  get filterByTargetSimFormControl() {
    // @ts-expect-error (legacy code incremental fix)
    return this.filterFormGroup.get('targetType').get('targetSim') as FormControl;
  }

  createNewEvent = () => {
    const path = '/event_handler/edit_event';

    this.router.navigate([path], { queryParams: this.getUrlParam() });
  };

  getUrlParam() {
    if (this.groupId) {
      return { groupId: this.groupId };
    } else if (this.imsi) {
      return { imsi: this.imsi };
    } else if (this.operator) {
      return { operator: this.operator };
    }

    return {};
  }

  deleteEventModalResult = async () => {
    this.isUpdating = true;
    const totalToDelete = this.selectedEventCache.length;
    const promises = this.selectedEventCache.map((handlerId) => {
      return this.soracomApiService
        .destroyEventHandler({ handlerId })
        .then((response: LegacyAny) => {
          return {
            ...response,
            handlerId,
          };
        })
        .catch((e: LegacyAny) => {
          throw {
            ...e,
            ...(typeof e === 'string' ? { message: e } : { handlerId }),
          };
        });
    });

    const erroredEvents = [];
    for (const promise of promises) {
      try {
        const response = await promise;

        this.logger.debug(response);
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          // remove deleted ones from list and cache
          // @ts-expect-error (legacy code incremental fix)
          this.selectedEventCache.splice(this.selectedEventCache.indexOf(response.handlerId), 1);
          this.eventListCache.removeEvent(response.handlerId);
        }
      } catch (e: LegacyAny) {
        erroredEvents.push(e.handlerId);
      }
    }

    // update list
    this.filterEvents();

    // TODO: if some error events are there, list gets updated, so we need to select checkbox for non-deleted events
    if (erroredEvents.length) {
      if (erroredEvents.length === totalToDelete) {
        this.alertManager.add(Alert.danger('eventHandlerList.deleteError.all'));
      } else {
        this.alertManager.add(
          Alert.danger(
            this.translationService.instant('eventHandlerList.deleteError.some', { count: erroredEvents.length })
          )
        );
      }
    } else {
      this.alertManager.add(Alert.success('eventHandlerList.deleteSuccess'));
    }
    this.isUpdating = false;
  };

  deleteEvents = async () => {
    this.alertManager.clear();
    if (this.selectedEventCache.length) {
      const confirmed = await this.modalService.openGenericConfirmModal('eventHandlerList.deleteConfirmDialog', {
        okButton: (b) => {
          b.buttonStyle = 'danger';
        },
      });
      if (confirmed) {
        this.deleteEventModalResult();
      }
    }
  };

  onSelectEventChange = ($event: LegacyAny, handlerId: LegacyAny) => {
    const isSelected = $event.target.checked;

    if (isSelected) {
      // @ts-expect-error (legacy code incremental fix)
      this.selectedEventCache.push(handlerId);
    } else {
      // @ts-expect-error (legacy code incremental fix)
      const searchIndex = this.selectedEventCache.indexOf(handlerId);
      this.selectedEventCache.splice(searchIndex, 1);
    }
  };

  closeTemplateDialog = () => {
    this.showImportTemplateModal = false;
    this.getEventList();
  };
}
