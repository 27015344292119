<div class="ds-modal">
  <div class="ds-dialog">
    <div class="ds-modal--loading-refresh" *ngIf="isUpdating"></div>
    <header class="ds-banner">
      <div>
        <h2 [innerHTML]="'eventHandlerTemplate.title' | translate"></h2>
        <p [innerHTML]="'eventHandlerTemplate.description' | translate"></p>
      </div>
    </header>

    <section>
      <ui-file-upload [onChange]="onFileUpload" testId="eventTemplateFileInput"></ui-file-upload>

      <json-editor [options]="editorOptions" [data]="templateData" #editor></json-editor>

      <app-alerts [alertsManager]="apiAlertManager"></app-alerts>
    </section>

    <footer>
      <ui-button-bar [content]="footerButtonBar"></ui-button-bar>
    </footer>
  </div>
</div>
