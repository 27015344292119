import { LegacyAny } from '@soracom/shared/core';

import { Component, ComponentRef, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoverageType } from '@foundation/coverage-type';
import { TranslateService } from '@ngx-translate/core';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OperatorConfigurationService } from '@soracom/shared-ng/operator-configuration';
import { Alert, AlertsManager, UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { FeatureVisibilityService, LoginUserDataService } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { CredentialsSet } from '../../../../app/shared/core/credentials_set';
import { UiInput } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiSelect } from '../../soracom-ui/ui-select/UiSelect';
import {
  EditEventNewActionComponent,
  getSpeedClassOptionIds,
} from '../edit-event-new-action/edit-event-new-action.component';
import { ComponentGeneratorFactoryService } from '../service/component-generator-factory.service';
import { Controls, EditEventHandlerService } from '../service/edit-event-handler.service';
import {
  EventHandlerFormValue,
  createReversibleObject,
  parseAPIDataToForm,
  processFormAndGenerateRequestBody,
} from '../utils/data-processor';
import { EventListCache } from '../utils/event-list-cache';
import { checkForValidityBeforeUpdate, scrollToFirstInvalidElement } from '../utils/form-submit-validator';

export enum EventTargetOptionIds {
  targetSimId = 'targetSimId',
  targetImsi = 'targetImsi',
  targetGroupId = 'targetGroupId',
  targetOperatorId = 'targetOperatorId',
}

const targetValueValidators: { [key: string]: { validators: ValidatorFn[]; updateOn: 'change' } } = {
  [EventTargetOptionIds.targetImsi]: {
    validators: [Validators.required, Validators.maxLength(15), Validators.minLength(15)],
    updateOn: 'change',
  },
  [EventTargetOptionIds.targetSimId]: {
    validators: [Validators.required],
    updateOn: 'change',
  },
  [EventTargetOptionIds.targetGroupId]: {
    validators: [Validators.required],
    updateOn: 'change',
  },
  [EventTargetOptionIds.targetOperatorId]: {
    validators: [Validators.required],
    updateOn: 'change',
  },
};

/**
 * To add new rule:
 *   - Add entry here and in eventRuleValueMapping
 *   - Add entry in eventRuleComponentStructure
 *   - If rule has some component, add entry in data-processor.ts/getEventRuleConfig()
 */
export enum EventRuleOptions {
  /** Deprecated rules */
  DailyTrafficRule = 'DailyTrafficRule',
  MonthlyTrafficRule = 'MonthlyTrafficRule',
  CumulativeTrafficRule = 'CumulativeTrafficRule',
  SessionStatusRule = 'SessionStatusRule',

  /** Subscriber Rules */
  SubscriberFirstTrafficRule = 'SubscriberFirstTrafficRule',
  SubscriberDailyTrafficRule = 'SubscriberDailyTrafficRule',
  SubscriberMonthlyTrafficRule = 'SubscriberMonthlyTrafficRule',
  SubscriberCumulativeTrafficRule = 'SubscriberCumulativeTrafficRule',
  SubscriberStatusAttributeRule = 'SubscriberStatusAttributeRule',
  SubscriberSpeedClassAttributeRule = 'SubscriberSpeedClassAttributeRule',
  SubscriberExpiredRule = 'SubscriberExpiredRule',
  SubscriberSessionStatusRule = 'SubscriberSessionStatusRule',
  SubscriberImeiMismatchedRule = 'SubscriberImeiMismatchedRule',

  /** SIM Rules */
  SimDailyTotalTrafficRule = 'SimDailyTotalTrafficRule',
  SimMonthlyTotalTrafficRule = 'SimMonthlyTotalTrafficRule',
  SimCumulativeTotalTrafficRule = 'SimCumulativeTotalTrafficRule',
  SimStatusAttributeRule = 'SimStatusAttributeRule',
  SimSpeedClassAttributeRule = 'SimSpeedClassAttributeRule',
  SimSubscriptionStatusRule = 'SimSubscriptionStatusRule',
  SimExpiredRule = 'SimExpiredRule',
  SimSessionStatusRule = 'SimSessionStatusRule',
  SimImeiMismatchedRule = 'SimImeiMismatchedRule',

  /** Other rules */
  DailyTotalTrafficRule = 'DailyTotalTrafficRule',
  MonthlyTotalTrafficRule = 'MonthlyTotalTrafficRule',
  DeviceRegisteredRule = 'DeviceRegisteredRule',
  MonthlyChargeRule = 'MonthlyChargeRule',
}

export const eventRuleValueMapping: LegacyAny = createReversibleObject({
  /** Deprecated rules */
  [EventRuleOptions.DailyTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.MonthlyTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.CumulativeTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.SessionStatusRule]: 'targetSessionStatus',

  /** Subscriber Rules */
  [EventRuleOptions.SubscriberFirstTrafficRule]: '',
  [EventRuleOptions.SubscriberDailyTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.SubscriberMonthlyTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.SubscriberCumulativeTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.SubscriberStatusAttributeRule]: 'targetStatus',
  [EventRuleOptions.SubscriberSpeedClassAttributeRule]: 'targetSpeedClass',
  [EventRuleOptions.SubscriberExpiredRule]: '',
  [EventRuleOptions.SubscriberSessionStatusRule]: 'targetSessionStatus',
  [EventRuleOptions.SubscriberImeiMismatchedRule]: '',

  /** Sim Rules */
  [EventRuleOptions.SimDailyTotalTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.SimMonthlyTotalTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.SimCumulativeTotalTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.SimStatusAttributeRule]: 'targetStatus',
  [EventRuleOptions.SimSpeedClassAttributeRule]: 'targetSpeedClass',
  [EventRuleOptions.SimSubscriptionStatusRule]: 'targetOtaStatus',
  [EventRuleOptions.SimExpiredRule]: '',
  [EventRuleOptions.SimSessionStatusRule]: 'targetSessionStatus',
  [EventRuleOptions.SimImeiMismatchedRule]: '',

  /** Other rules */
  [EventRuleOptions.DailyTotalTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.DeviceRegisteredRule]: '',
  [EventRuleOptions.MonthlyTotalTrafficRule]: 'limitTotalTrafficMegaByte',
  [EventRuleOptions.MonthlyChargeRule]: 'limitTotalAmount',
});

const DEPRECATED_RULES = [
  EventRuleOptions.DailyTrafficRule,
  EventRuleOptions.MonthlyTrafficRule,
  EventRuleOptions.CumulativeTrafficRule,
  EventRuleOptions.SessionStatusRule,
];

export enum RuleReEvaluateOptions {
  IMMEDIATELY = 'IMMEDIATELY',
  BEGINNING_OF_NEXT_MONTH = 'BEGINNING_OF_NEXT_MONTH',
  BEGINNING_OF_NEXT_DAY = 'BEGINNING_OF_NEXT_DAY',
  AFTER_ONE_DAY = 'AFTER_ONE_DAY',
  NEVER = 'NEVER',
}

export const subscriberStatusAttributeOptionIds = [
  'null',
  'ready',
  'active',
  'inactive',
  'standby',
  'suspended',
  'terminated',
];

export const getRulesSpeedClassAttributeOptionsIds = (featureVisibilityService: FeatureVisibilityService) =>
  ['null'].concat(getSpeedClassOptionIds(featureVisibilityService));

export const sessionStatusOptionsIds = ['null', 'Created', 'Deleted'];

export const simSubscriptionStatusOptionsIds = ['null', 'started', 'finished', 'failed'];

interface EventRuleComponentStructure {
  eventRuleId: string;
  supportedTargets: string[];
  components: Array<{
    generateComponent: () => ComponentRef<any>;
  }>;
}

const defaultEventConfig: EventHandlerFormValue = {
  eventName: '',
  description: '',
  isActive: true,
  eventTarget: EventTargetOptionIds.targetImsi,
  targetValue: '',
  eventRule: {
    rule: EventRuleOptions.SubscriberDailyTrafficRule,
    ruleValue: '',
    inactiveTimeoutDateConst: RuleReEvaluateOptions.BEGINNING_OF_NEXT_DAY,
    inactiveTimeoutOffsetMinutes: '',
    runOnceAmongTarget: false,
  },
  actions: {},
};

function getReEvaulatePeriodDefaultValue(rule: EventRuleOptions) {
  switch (rule) {
    case EventRuleOptions.SubscriberDailyTrafficRule:
    case EventRuleOptions.SimDailyTotalTrafficRule:
    case EventRuleOptions.DailyTotalTrafficRule:
      return RuleReEvaluateOptions.BEGINNING_OF_NEXT_DAY;
    case EventRuleOptions.SubscriberMonthlyTrafficRule:
    case EventRuleOptions.SimMonthlyTotalTrafficRule:
    case EventRuleOptions.MonthlyTotalTrafficRule:
      return RuleReEvaluateOptions.BEGINNING_OF_NEXT_MONTH;
    case EventRuleOptions.SubscriberFirstTrafficRule:
    case EventRuleOptions.SubscriberCumulativeTrafficRule:
    case EventRuleOptions.SimCumulativeTotalTrafficRule:
      return RuleReEvaluateOptions.NEVER;
    default:
      return RuleReEvaluateOptions.IMMEDIATELY;
  }
}

const MAX_ACTION = 5;

@Component({
  selector: 'app-edit-event-handler',
  templateUrl: './edit-event-handler.component.html',
  providers: [EditEventHandlerService, ComponentGeneratorFactoryService],
  styles: [
    `
      ds-cols ui-button {
        margin-right: 0;
      }
    `,
  ],
})
export class EditEventHandlerComponent implements OnInit {
  EventRuleOptions = EventRuleOptions;
  EventTargetOptionIds = EventTargetOptionIds;
  // @ts-expect-error (legacy code incremental fix)
  eventHandlerForm: FormGroup;

  eventName = new UiInput();
  description = new UiInput();
  targetValue = new UiInput();
  reEvaluateRuleOffsetTime = new UiInput();

  // @ts-expect-error (legacy code incremental fix)
  eventTarget: UiSelect;
  // @ts-expect-error (legacy code incremental fix)
  eventRule: UiSelect;
  // @ts-expect-error (legacy code incremental fix)
  reEvaluateSelect: UiSelect;
  sourceStatus: UiSelect;

  addActionButton = new UiButton();
  updateButton = new UiButton();
  createButton = new UiButton();
  cancelButton = new UiButton();
  deleteButton = new UiButton();

  apiAlertManager = new AlertsManager();
  formAlertManager = new AlertsManager();
  subscriberStatusAttributeErrorAlertManager = new AlertsManager();

  eventRuleComponentStructure: EventRuleComponentStructure[];

  isActive = false;
  isUpdating = false;
  isFetching = false;

  /** Will come from groups page */
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;

  /** This will come from subscriber's page */
  // @ts-expect-error (legacy code incremental fix)
  imsi: string;

  /** This will come from subscriber's page */
  // @ts-expect-error (legacy code incremental fix)
  operator: boolean;

  /** Either will hold config from api or default config */
  private eventConfig: EventHandlerFormValue = defaultEventConfig;

  // This will be always increasing as user add new actions to maintain unique control names
  private actionControlIndex = 0;

  // This will hold action count
  private actionCount = 0;

  // Holds aws credentials data, which will be used when user selects invokeAwsLambda action
  private awsCredentialsData: CredentialsSet[] = [];

  // Holds coverageType to show billing currency correctly
  // @ts-expect-error (legacy code incremental fix)
  private coverageType: CoverageType;

  public handlerId: LegacyAny;

  /** The currency depends on the operator, then coverage type. It is used for e.g. alerts if bill exceeds X. */
  private currency?: string;

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('newActionContainerRef', { read: ViewContainerRef, static: true }) newActionContainerRef: ViewContainerRef;
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('ruleValueContainerRef', { read: ViewContainerRef, static: true }) ruleValueContainerRef: ViewContainerRef;
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('eventHandlerFormRef', { read: ElementRef, static: true }) eventHandlerFormRef: ElementRef<HTMLElement>;

  constructor(
    public editEventHandlerService: EditEventHandlerService,
    private coverageTypeService: CoverageTypeService,
    private translate: TranslateService,
    private componentGeneratorFactoryService: ComponentGeneratorFactoryService,
    private soracomApiService: SoracomApiService,
    private modalService: UiDsModalService,
    private loginUserDataService: LoginUserDataService,
    private featureVisibilityService: FeatureVisibilityService,
    private router: Router,
    private route: ActivatedRoute,
    private operatorConfigurationService: OperatorConfigurationService
  ) {
    this.route.params.pipe(takeUntilDestroyed()).subscribe((params) => {
      this.handlerId = params.handlerId;
    });

    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      this.groupId = params?.groupId;
      this.imsi = params?.imsi;
      this.operator = params?.operator;
    });

    this.eventRuleComponentStructure = [
      /** Deprecated */
      {
        eventRuleId: EventRuleOptions.DailyTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.MonthlyTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.CumulativeTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },

      /** Subscriber rules */
      {
        eventRuleId: EventRuleOptions.SubscriberFirstTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [],
      },
      {
        eventRuleId: EventRuleOptions.SubscriberDailyTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SubscriberMonthlyTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SubscriberCumulativeTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SubscriberStatusAttributeRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: () => {
              const selectComponentRef = this.ruleValueSelectComponent(
                subscriberStatusAttributeOptionIds,
                'editEvent.subscriberStatusAttribute',
                'editEvent.subscriberStatusAttribute.targetStatusLabel'
              );
              selectComponentRef.instance.onChange = this.onSimOrSubscriberStatusAttributeChange;

              return selectComponentRef;
            },
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SubscriberSpeedClassAttributeRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [
          {
            generateComponent: () => {
              return this.ruleValueSelectComponent(
                getRulesSpeedClassAttributeOptionsIds(this.featureVisibilityService),
                'editEvent.subscriberSpeedClassAttribute'
              );
            },
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SubscriberExpiredRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [],
      },
      {
        eventRuleId: EventRuleOptions.SubscriberImeiMismatchedRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [],
      },

      /** Sim rules */
      {
        eventRuleId: EventRuleOptions.SimDailyTotalTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetSimId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SimMonthlyTotalTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetSimId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SimCumulativeTotalTrafficRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetSimId,
        ],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SimStatusAttributeRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetSimId,
        ],
        components: [
          {
            generateComponent: () => {
              const selectComponentRef = this.ruleValueSelectComponent(
                subscriberStatusAttributeOptionIds,
                'editEvent.subscriberStatusAttribute',
                'editEvent.subscriberStatusAttribute.targetStatusLabel'
              );
              selectComponentRef.instance.onChange = this.onSimOrSubscriberStatusAttributeChange;

              return selectComponentRef;
            },
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SimSpeedClassAttributeRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetSimId,
        ],
        components: [
          {
            generateComponent: () => {
              return this.ruleValueSelectComponent(
                getRulesSpeedClassAttributeOptionsIds(this.featureVisibilityService),
                'editEvent.subscriberSpeedClassAttribute'
              );
            },
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SimSubscriptionStatusRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetSimId,
        ],
        components: [
          {
            generateComponent: () => {
              return this.ruleValueSelectComponent(simSubscriptionStatusOptionsIds, 'editEvent.simSubscriptionStatus');
            },
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.SimExpiredRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetSimId,
        ],
        components: [],
      },
      {
        eventRuleId: EventRuleOptions.SimImeiMismatchedRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetSimId,
          EventTargetOptionIds.targetGroupId,
        ],
        components: [],
      },

      /** Other rules */
      {
        eventRuleId: EventRuleOptions.DailyTotalTrafficRule,
        supportedTargets: [EventTargetOptionIds.targetOperatorId, EventTargetOptionIds.targetGroupId],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
      {
        eventRuleId: EventRuleOptions.MonthlyTotalTrafficRule,
        supportedTargets: [EventTargetOptionIds.targetOperatorId, EventTargetOptionIds.targetGroupId],
        components: [
          {
            generateComponent: this.createDataTrafficInputComponent,
          },
        ],
      },
    ];

    if (this.featureVisibilityService.isEnabled('eventHandlerSubscriberSessionStatusRule')) {
      this.eventRuleComponentStructure.push({
        eventRuleId: EventRuleOptions.SubscriberSessionStatusRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: this.createSessionStatusComponent(),
      });
    }

    if (this.featureVisibilityService.isEnabled('eventHandlerSessionStatusRule')) {
      this.eventRuleComponentStructure.push({
        eventRuleId: EventRuleOptions.SessionStatusRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetImsi,
          EventTargetOptionIds.targetGroupId,
        ],
        components: this.createSessionStatusComponent(),
      });
    }

    if (this.featureVisibilityService.isEnabled('eventHandlerSIMSessionStatusRule')) {
      this.eventRuleComponentStructure.push({
        eventRuleId: EventRuleOptions.SimSessionStatusRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetSimId,
          EventTargetOptionIds.targetGroupId,
        ],
        components: this.createSessionStatusComponent(),
      });
    }

    if (this.featureVisibilityService.isEnabled('eventHandlerDeviceRegisteredRule')) {
      this.eventRuleComponentStructure.push({
        eventRuleId: EventRuleOptions.DeviceRegisteredRule,
        supportedTargets: [
          EventTargetOptionIds.targetOperatorId,
          EventTargetOptionIds.targetSimId,
          EventTargetOptionIds.targetGroupId,
          EventTargetOptionIds.targetImsi,
        ],
        components: [],
      });
    }

    if (this.featureVisibilityService.isEnabled('eventHandlerMonthlyChargeRule')) {
      this.eventRuleComponentStructure.push({
        eventRuleId: EventRuleOptions.MonthlyChargeRule,
        supportedTargets: [EventTargetOptionIds.targetOperatorId],
        components: [
          {
            generateComponent: this.createMonthlyChargeRuleInputComponent,
          },
        ],
      });
    }

    this.sourceStatus = new UiSelect(subscriberStatusAttributeOptionIds.filter((id) => id !== 'terminated'));
    this.sourceStatus.optionFormatter = (option) => {
      return this.translate.instant(`editEvent.subscriberStatusAttribute.sourceStatus.${option}`);
    };
    this.sourceStatus.labelId = 'editEvent.subscriberStatusAttribute.sourceStatusLabel';
    this.sourceStatus.value = this.eventConfig.eventRule.sourceStatus || 'null';
    this.sourceStatus.onValueChange = this.onSourceStatusChange;
  }

  setupEventHandlerForm() {
    if (this.editEventHandlerService.formGroup) {
      // @ts-expect-error (legacy code incremental fix)
      this.editEventHandlerService.formGroup = null;
    }

    this.editEventHandlerService.addControlsToForm(this.getFormControlConfig());
    this.eventHandlerForm = this.editEventHandlerService.formGroup;

    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: 'runOnceAmongTarget',
      controlValue: this.eventConfig.eventRule.runOnceAmongTarget,
      validators: [],
      parentFormNames: this.eventRuleParentControlName,
    });
  }

  getFormControlConfig(): Controls {
    return {
      eventName: {
        value: this.eventConfig.eventName,
        validators: [Validators.required],
      },
      description: {
        value: this.eventConfig.description,
      },
      targetValue: {
        value: this.eventConfig.targetValue,
        validators: targetValueValidators[this.eventConfig.eventTarget].validators,
        updateOn: 'blur',
      },
      isActive: {
        value: this.eventConfig.isActive,
      },
    };
  }

  setupInputs() {
    this.eventName.formControl = this.eventNameControl;
    this.eventName.labelId = 'editEvent.label.eventName';
    this.eventName.required = true;
    this.eventName.validationErrorMessageStringId = 'editEvent.inpuValidationMessage.eventName';
    this.eventName.testId = 'eventName';

    this.description.formControl = this.descriptionControl;
    this.description.labelId = 'editEvent.label.description';
    this.description.testId = 'description';

    this.targetValue.formControl = this.targetValueControl;
    this.targetValue.layout = 'horizontal';
    this.targetValue.validationErrorMessageStringId = `editEvent.inpuValidationMessage.targetValue.${this.eventTarget.value}`;
    this.targetValue.testId = 'targetValue';
    this.targetValue.status =
      this.eventConfig.eventTarget === EventTargetOptionIds.targetOperatorId ? 'readonly' : 'default';

    // @ts-expect-error (legacy code incremental fix)
    this.reEvaluateRuleOffsetTime.formControl = this.editEventHandlerService.getControl(
      `${this.eventRuleParentControlName}.inactiveTimeoutOffsetMinutes`
    );
    this.reEvaluateRuleOffsetTime.labelId = 'editEvent.label.offsetTime';
    this.reEvaluateRuleOffsetTime.testId = 'reEvaluateRuleOffsetTime';
  }

  setupSelects() {
    this.setupEventTargetSelect();
    this.setupEventRuleSelect();
    this.setupReEvaluateSelect();
  }

  setupEventTargetSelect() {
    this.eventTarget = new UiSelect(Object.keys(EventTargetOptionIds));
    this.eventTarget.optionFormatter = (option) => {
      return `${this.translate.instant(`editEvent.eventTarget.${option}`)}`;
    };
    this.eventTarget.value = this.eventConfig.eventTarget;
    this.eventTarget.onValueChange = this.onTargetChange;
    // add control to formGroup
    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: 'eventTarget',
      controlValue: this.eventConfig.eventTarget,
      validators: [],
    });
    this.eventTarget.testId = 'eventTarget';
  }

  setupEventRuleSelect() {
    this.eventRule = new UiSelect(this.getEventRuleOptions());
    this.eventRule.optionFormatter = (option) => {
      return `${this.translate.instant(`editEvent.eventRule.${option}`)}`;
    };
    this.eventRule.optionDisabler = (option) => DEPRECATED_RULES.includes(option);
    this.eventRule.onValueChange = this.onRuleChange;
    // add control to formGroup
    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: 'rule',
      controlValue: this.eventConfig.eventRule.rule,
      validators: [],
      parentFormNames: this.eventRuleParentControlName,
    });
    this.eventRule.value = this.eventConfig.eventRule.rule;
    this.eventRule.testId = 'eventRule';
  }

  setupReEvaluateSelect() {
    this.reEvaluateSelect = new UiSelect(Object.keys(RuleReEvaluateOptions));
    this.reEvaluateSelect.optionFormatter = (option) => {
      return `${this.translate.instant(`editEvent.eventRuleReEvaluate.${option}`)}`;
    };
    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: 'inactiveTimeoutOffsetMinutes',
      controlValue: this.eventConfig.eventRule.inactiveTimeoutOffsetMinutes,
      validators: [],
      parentFormNames: this.eventRuleParentControlName,
    });
    this.reEvaluateSelect.labelId = 'editEvent.eventRuleReEvaluate.label';
    this.reEvaluateSelect.labelPosition = 'top';
    this.reEvaluateSelect.onValueChange = this.onRuleReEvaluateChange;
    // add control to formGroup
    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: 'inactiveTimeoutDateConst',
      controlValue: this.eventConfig.eventRule.inactiveTimeoutDateConst,
      validators: [],
      parentFormNames: this.eventRuleParentControlName,
    });
    this.reEvaluateSelect.value = this.eventConfig.eventRule.inactiveTimeoutDateConst;
    this.reEvaluateSelect.testId = 'reEvaluate';
  }

  setupButtons() {
    this.addActionButton.titleId = 'editEvent.button.addAction';
    this.addActionButton.iconName = 'icon-plus';
    this.addActionButton.onClick = this.addNewAction;
    this.addActionButton.isDisabled_ƒ = () => this.actionCount >= MAX_ACTION;

    this.updateButton.titleId = 'editEvent.button.updateEvent';
    this.updateButton.onClick = this.updateEvent;
    this.updateButton.buttonStyle = 'primary';

    this.createButton.titleId = 'editEvent.button.createEvent';
    this.createButton.onClick = this.createEvent;
    this.createButton.buttonStyle = 'primary';

    this.cancelButton.titleId = 'editEvent.button.cancel';
    this.cancelButton.onClick = this.cancel;

    this.deleteButton.titleId = 'editEvent.button.delete';
    this.deleteButton.onClick = this.deleteEvent;
    this.deleteButton.iconName = 'icon-delete';
    this.deleteButton.buttonStyle = 'danger';
  }

  getEventRuleOptions() {
    return this.eventRuleComponentStructure
      .filter((value: LegacyAny) => {
        if (value.supportedTargets.includes(this.eventTarget.value)) {
          // For create new, don't show deprecated rules
          if (!this.handlerId && DEPRECATED_RULES.includes(value.eventRuleId as EventRuleOptions)) {
            return false;
          }
          return true;
        }
      })
      .map((value: LegacyAny) => value.eventRuleId);
  }

  get showHasImeiCheck() {
    return (
      this.eventRule &&
      [
        EventRuleOptions.SubscriberSessionStatusRule,
        EventRuleOptions.SimSessionStatusRule,
        EventRuleOptions.SessionStatusRule,
      ].includes(this.eventRule.value) &&
      this.featureVisibilityService.isEnabled('eventHandlerImeiLockAction')
    );
  }

  async ngOnInit() {
    const coverageType = this.coverageTypeService.getCoverageType();
    // @ts-expect-error (legacy code incremental fix)
    this.currency = this.operatorConfigurationService.operatorCurrencyOrLegacyFallback(coverageType);

    this.setupEventHandlerForm();

    // fetch credentials id in advance from api for invoke aws lambda action
    await this.fetchCredentialsData();

    // if there is handlerId it means there is an event to update,
    // so get data from cache and fill in the form
    if (this.handlerId) {
      let apiData = EventListCache.instance.getByHandlerId(this.handlerId);

      // If there is no data for this handlerId, means may be user refreshed page
      // so try to fetch from API and save in cache
      if (!apiData) {
        apiData = await this.fetchEventData();

        // handlerId does not exist, move to event list screen
        // which is unlikely to happen unless intentionally copy url with id and delete that event and open copied url again
        if (!apiData) {
          window.location.href = '/event_handler/';
          return;
        }

        EventListCache.instance.addToList([apiData]);
      }
      this.eventConfig = parseAPIDataToForm(apiData);
      this.setupComponents();
      this.generateActionComponents();

      return;
    }

    // If no handlerId means this is create event page and if groupId exists means it's coming from groups page
    if (this.groupId) {
      this.eventConfig.eventTarget = EventTargetOptionIds.targetGroupId;
      this.eventConfig.targetValue = this.groupId;
    }

    if (this.imsi) {
      this.eventConfig.eventTarget = EventTargetOptionIds.targetImsi;
      this.eventConfig.targetValue = this.imsi;
    }

    if (this.operator) {
      this.eventConfig.eventTarget = EventTargetOptionIds.targetOperatorId;
      // @ts-expect-error (legacy code incremental fix)
      this.eventConfig.targetValue = this.loginUserDataService.getLoginUser()?.operatorId;
    }

    this.setupComponents();
  }

  setupComponents() {
    this.setupEventHandlerForm();
    this.setupSelects();
    this.setupInputs();
    this.setupButtons();
  }

  async fetchEventData() {
    this.isFetching = true;
    this.formAlertManager.clear();
    try {
      const response = await this.soracomApiService.getEventHandlers('');
      // Filter data by existing handlerId
      const data = response.data.find((datum: LegacyAny) => datum.handlerId === this.handlerId);

      return data;
    } catch (e) {
      this.formAlertManager.add(Alert.fromApiError(e, 'editEvent.message.commonError'));
    } finally {
      setTimeout(() => {
        this.isFetching = false;
      }, 1000);
    }
  }

  async fetchCredentialsData() {
    this.isFetching = true;
    try {
      const response = await this.soracomApiService.listCredentialsSets();
      // filter aws credentials
      this.awsCredentialsData = response.data.filter(
        (row: LegacyAny) => row.type === 'aws-credentials' || row.type === 'aws-iam-role-credentials'
      );
    } catch (e) {
      this.awsCredentialsData = [];
    } finally {
      this.isFetching = false;
    }
  }

  onTargetChange = () => {
    // Reset value of input too
    this.targetValue.value = '';
    this.targetValue.status = 'default';
    // And update target value validators too!
    this.targetValueControl.clearValidators();
    const config = targetValueValidators[this.eventTarget.value];
    this.targetValueControl.setValidators(config.validators);
    this.targetValueControl.updateValueAndValidity();
    this.targetValue.validationErrorMessageStringId = `editEvent.inpuValidationMessage.targetValue.${this.eventTarget.value}`;

    this.subscriberStatusAttributeErrorAlertManager.clear();
    if (this.eventTarget.value === EventTargetOptionIds.targetOperatorId) {
      this.targetValue.value = this.loginUserDataService.getLoginUser()?.operatorId;
      this.targetValue.status = 'readonly';
    }

    // Need to update formGroup control
    const control = this.editEventHandlerService.getControl('eventTarget');
    // @ts-expect-error (legacy code incremental fix)
    control.setValue(this.eventTarget.value);
    // @ts-expect-error (legacy code incremental fix)
    control.markAsDirty();

    // Update rules according to target
    this.eventRule.resetOptions();
    const newOptions = this.getEventRuleOptions();
    this.eventRule.addValues(newOptions);
    this.eventRule.value = newOptions[0];
    this.onRuleChange();
  };

  onRuleChange = () => {
    const selectedRule = this.eventRule.value;
    // @ts-expect-error (legacy code incremental fix)
    const components = this.eventRuleComponentStructure.find((rule) => rule.eventRuleId === selectedRule).components;

    if (!components.length) {
      this.ruleValueContainerRef.clear();
    }

    this.subscriberStatusAttributeErrorAlertManager.clear();
    if (this.shouldShowSourceStatus()) {
      this.sourceStatus.value = this.eventConfig?.eventRule?.sourceStatus || 'null';
    }

    // Need to update formGroup control
    const control = this.editEventHandlerService.getControl(`${this.eventRuleParentControlName}.rule`);
    // @ts-expect-error (legacy code incremental fix)
    control.setValue(this.eventRule.value);
    // @ts-expect-error (legacy code incremental fix)
    control.markAsDirty();

    if (this.showHasImeiCheck) {
      this.componentGeneratorFactoryService.addControlToFormGroup({
        controlName: 'hasImei',
        controlValue: this.eventConfig.eventRule.hasImei,
        validators: [],
        parentFormNames: this.eventRuleParentControlName,
      });
      // @ts-expect-error (legacy code incremental fix)
      this.editEventHandlerService
        .getControl(`${this.eventRuleParentControlName}.hasImei`)
        // @ts-expect-error (legacy code incremental fix)
        .setValue(
          this.eventRule.value === this.eventConfig.eventRule.rule ? this.eventConfig.eventRule.hasImei : false
        );
    } else {
      this.editEventHandlerService.removeControl(`${this.eventRuleParentControlName}.hasImei`);
    }

    if (this.shouldShowSourceStatus()) {
      this.componentGeneratorFactoryService.addControlToFormGroup({
        controlName: 'sourceStatus',
        controlValue: this.eventConfig.eventRule.sourceStatus,
        validators: [],
        parentFormNames: this.eventRuleParentControlName,
      });
    } else {
      this.editEventHandlerService.removeControl(`${this.eventRuleParentControlName}.sourceStatus`);
    }

    // Set default value of reEvaluate period
    if (this.reEvaluateSelect) {
      this.reEvaluateSelect.value = getReEvaulatePeriodDefaultValue(this.eventRule.value);
    }

    // Reset ruleValue control
    this.editEventHandlerService.removeControl(`${this.eventRuleParentControlName}.ruleValue`);
    if (selectedRule !== this.eventConfig.eventRule.rule) {
      this.eventConfig.eventRule.ruleValue = '';
    }

    components.forEach((component) => {
      setTimeout(() => {
        const componentRef = component.generateComponent();
        componentRef.changeDetectorRef.detectChanges();
      }, 0);
    });
  };

  onSourceStatusChange = () => {
    this.editEventHandlerService
      .getControl(`${this.eventRuleParentControlName}.sourceStatus`)
      // @ts-expect-error (legacy code incremental fix)
      ?.setValue(this.sourceStatus.value);
    this.onSimOrSubscriberStatusAttributeChange();
  };

  onSimOrSubscriberStatusAttributeChange = () => {
    this.subscriberStatusAttributeErrorAlertManager.clear();
    if (this.sourceStatus.value === this.eventHandlerForm.value.eventRule.ruleValue) {
      this.subscriberStatusAttributeErrorAlertManager.add(
        Alert.danger('editEvent.subscriberStatusAttribute.statusShouldNotBeSame')
      );
    }
  };

  onRuleReEvaluateChange = () => {
    // Need to update formGroup control
    const control = this.editEventHandlerService.getControl(
      `${this.eventRuleParentControlName}.inactiveTimeoutDateConst`
    );
    // @ts-expect-error (legacy code incremental fix)
    control.setValue(this.reEvaluateSelect.value);
    // @ts-expect-error (legacy code incremental fix)
    control.markAsDirty();

    if (this.reEvaluateSelect.value === RuleReEvaluateOptions.NEVER) {
      const offsetControl = this.editEventHandlerService.getControl(
        `${this.eventRuleParentControlName}.inactiveTimeoutOffsetMinutes`
      );
      // @ts-expect-error (legacy code incremental fix)
      offsetControl.setValue('');
      // @ts-expect-error (legacy code incremental fix)
      offsetControl.markAsDirty();
    }

    this.reEvaluateRuleOffsetTime.status =
      this.reEvaluateSelect.value === RuleReEvaluateOptions.NEVER ? 'readonly' : 'default';
  };

  shouldShowSourceStatus() {
    return (
      this.eventRule &&
      [EventRuleOptions.SimStatusAttributeRule, EventRuleOptions.SubscriberStatusAttributeRule].includes(
        this.eventRule.value
      )
    );
  }

  getEventTargetValueKey() {
    if (!this.eventTarget) {
      return 'imsi';
    }

    switch (this.eventTarget.value) {
      case EventTargetOptionIds.targetGroupId:
        return 'groupId';
      case EventTargetOptionIds.targetSimId:
        return 'simId';
      case EventTargetOptionIds.targetImsi:
      default:
        return 'imsi';
    }
  }

  createDataTrafficInputComponent = () => {
    return this.componentGeneratorFactoryService.createInputComponent(
      {
        controlName: 'ruleValue',
        controlValue: this.eventConfig.eventRule.ruleValue,
        validators: [Validators.required, Validators.pattern(/^\d+$/)],
        suffixStringId: 'editEvent.ruleTrafficInputSuffix',
        parentFormNames: this.eventRuleParentControlName,
        validationErrorMessageStringId: 'editEvent.inpuValidationMessage.eventRuleTrafficInput',
        testId: 'ruleValue',
      },
      this.ruleValueContainerRef
    );
  };

  ruleValueSelectComponent(options: LegacyAny, translationPrefix: LegacyAny, labelId?: string) {
    const ruleValue = this.eventConfig.eventRule.ruleValue;

    return this.componentGeneratorFactoryService.createSelectComponent(
      {
        controlName: 'ruleValue',
        value: !ruleValue ? 'null' : ruleValue,
        options,
        labelId,
        translationPrefix,
        validators: [],
        parentFormNames: this.eventRuleParentControlName,
      },
      this.ruleValueContainerRef
    );
  }

  createSessionStatusComponent = () => {
    return [
      {
        generateComponent: () => {
          const ruleValue = this.eventConfig.eventRule.ruleValue;

          return this.componentGeneratorFactoryService.createSelectComponent(
            {
              controlName: 'ruleValue',
              value: !ruleValue ? 'null' : ruleValue,
              options: sessionStatusOptionsIds,
              translationPrefix: 'editEvent.sessionStatus',
              validators: [],
              parentFormNames: this.eventRuleParentControlName,
            },
            this.ruleValueContainerRef
          );
        },
      },
    ];
  };

  createMonthlyChargeRuleInputComponent = () => {
    return this.componentGeneratorFactoryService.createInputComponent(
      {
        controlName: 'ruleValue',
        controlValue: this.eventConfig.eventRule.ruleValue,
        validators: [Validators.required, Validators.pattern(/^\d+$/)],
        suffixStringId: `currency.${this.currency}.postfixSymbol`,
        parentFormNames: this.eventRuleParentControlName,
        validationErrorMessageStringId: 'editEvent.inpuValidationMessage.eventRuleChargeAmountInput',
        testId: 'ruleValue',
        // <div class="line-breaker"></div> in edit-event-handler.component.html breaks its alignment
        // labelId: 'editEvent.ruleChargeAmountInputLabel',
      },
      this.ruleValueContainerRef
    );
  };

  get eventNameControl() {
    return this.eventHandlerForm.get('eventName') as FormControl;
  }
  get descriptionControl() {
    return this.eventHandlerForm.get('description') as FormControl;
  }
  get targetValueControl() {
    return this.eventHandlerForm.get('targetValue') as FormControl;
  }
  get isActiveControl() {
    return this.eventHandlerForm && (this.eventHandlerForm.get('isActive') as FormControl);
  }

  get eventRuleParentControlName() {
    return 'eventRule';
  }

  /**
   * Generates action components when there is value from API, i.e. edit screen
   */
  generateActionComponents() {
    const { actions } = this.eventConfig;
    if (this.handlerId && actions) {
      Object.keys(actions).forEach((actionKey) => {
        const generatedComponent = this.addNewAction();
        generatedComponent.instance.eventActionConfig = {
          actionType: actionKey.split('-')[0],
          ...actions[actionKey],
        };
      });
    }
  }

  addNewAction = () => {
    const component = this.componentGeneratorFactoryService.createComponent(
      EditEventNewActionComponent,
      this.newActionContainerRef,
      false
    );
    component.instance.removeAction = this.removeAction;
    component.instance.viewReference = component;
    component.instance.componentIndex = ++this.actionControlIndex;
    component.instance.handlerId = this.handlerId;
    component.instance.awsCredentialsData = this.awsCredentialsData;
    this.actionCount++;
    this.apiAlertManager.clear();

    return component;
  };

  removeAction = (component: any) => {
    this.newActionContainerRef.remove(this.newActionContainerRef.indexOf(component.hostView));
    this.actionCount--;
  };

  cancel = () => {
    const path = '/event_handler';
    let search = {};

    if (this.groupId) {
      search = { groupId: this.groupId };
    } else if (this.imsi) {
      search = { imsi: this.imsi };
    } else if (this.operator) {
      search = { operator: this.operator };
    }

    this.router.navigate([path], { queryParams: search });
  };

  update = async (updateType: 'create' | 'update') => {
    this.apiAlertManager.clear();
    const eventHandlerForm = this.editEventHandlerService.formGroup;

    checkForValidityBeforeUpdate(eventHandlerForm);

    if (!eventHandlerForm.valid) {
      scrollToFirstInvalidElement(this.eventHandlerFormRef.nativeElement);
      return;
    }

    const actions = this.editEventHandlerService.getControl('actions');

    // check if there is at least one action added
    if (!actions || (actions && !Object.keys(actions).length)) {
      this.apiAlertManager.add(Alert.danger('editEvent.message.actionRequired'));
      return;
    }

    // check for whether source status and target status values are same
    // for Sim/Subscriber status attribute rule
    if (this.subscriberStatusAttributeErrorAlertManager.alerts.length) {
      return;
    }

    if (
      eventHandlerForm.value.eventRule.sourceStatus === '' ||
      eventHandlerForm.value.eventRule.sourceStatus === 'null'
    ) {
      eventHandlerForm.value.eventRule.sourceStatus = null;
    }

    this.isUpdating = true;
    try {
      const request = processFormAndGenerateRequestBody(eventHandlerForm, this.handlerId);
      const response = await (updateType === 'create'
        ? this.soracomApiService.createEventHandler(request)
        : this.soracomApiService.updateEventHandler(request));

      if ((response && response.status === 200) || response.status === 201) {
        this.apiAlertManager.add(
          Alert.success(`editEvent.message.${updateType === 'create' ? 'createSuccess' : 'updateSuccess'}`)
        );

        // redirect to list
        if (updateType === 'create') {
          this.createButton.isDisabled = true;
          setTimeout(() => {
            this.cancel();
          }, 1000);
        }

        return;
      }

      throw response;
    } catch (e) {
      this.apiAlertManager.add(Alert.fromApiError(e, 'editEvent.message.commonError'));
    } finally {
      this.isUpdating = false;
    }
  };

  createEvent = async () => {
    await this.update('create');
  };

  updateEvent = async () => {
    await this.update('update');
  };

  deleteEvent = async () => {
    const confirmed = await this.modalService.openGenericConfirmModal('editEvent.deleteConfirmDialog', {
      okButton: (b) => {
        b.buttonStyle = 'danger';
      },
    });
    if (confirmed) {
      this.deleteEventModalResult();
    }
  };

  deleteEventModalResult = async () => {
    this.isUpdating = true;
    this.apiAlertManager.clear();
    try {
      const response = await this.soracomApiService.destroyEventHandler({ handlerId: this.handlerId });

      if ((response && response.status === 200) || response.status === 201 || response.status === 204) {
        this.apiAlertManager.add(Alert.success('editEvent.message.deleteSuccess'));
        this.updateButton.isDisabled = true;
        this.deleteButton.isDisabled = true;
        setTimeout(() => {
          this.cancel();
        }, 1000);

        return;
      }

      throw response;
    } catch (e) {
      this.apiAlertManager.add(Alert.fromApiError(e, 'editEvent.message.commonError'));
    } finally {
      this.isUpdating = false;
    }
  };
}
