import { EventConfig } from './data-processor';

export interface EventConfigList {
  [key: string]: EventConfig;
}

interface EventListCache {
  _eventList: EventConfigList;
  _instance: EventListCache;
  readonly instance: {
    getList(): EventConfigList;
    getByHandlerId(handlerId: string): EventConfig;
    addToList(eventList: EventConfig[]): void;
    clearList(): void;
    removeEvent(handlerId: string): void;
  };
}

export const EventListCache: EventListCache = {
  _eventList: {},
  // @ts-expect-error (legacy code incremental fix)
  _instance: null,

  // @ts-expect-error (legacy code incremental fix)
  get instance() {
    if (!this._instance) {
      this._instance = {
        // @ts-expect-error (legacy code incremental fix)
        getList(): EventConfigList {
          return this._eventList;
        },

        getByHandlerId(handlerId: string): EventConfig {
          return this._eventList && this._eventList[handlerId];
        },

        addToList(eventList: EventConfig[]) {
          // Convert array type to object
          eventList.forEach((event) => {
            this._eventList = {
              ...this._eventList,
              // @ts-expect-error (legacy code incremental fix)
              [event.handlerId]: event,
            };
          });
        },

        clearList() {
          this._eventList = {};
        },

        removeEvent(handlerId: string) {
          // @ts-expect-error (legacy code incremental fix)
          if (this.getByHandlerId(handlerId)) {
            delete this._eventList[handlerId];
          }
        },
      };
    }

    return this._instance;
  },
};
