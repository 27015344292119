import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

interface Control {
  value?: string | number | boolean;
  validators?: Validators;
  updateOn?: 'change' | 'blur' | 'submit';
}

export interface Controls {
  [key: string]: Control | Controls;
}

@Injectable()
export class EditEventHandlerService {
  // @ts-expect-error (legacy code incremental fix)
  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  private addControls(controls: Controls) {
    let groupConfig = {};
    Object.keys(controls).forEach((controlName) => {
      const control = controls[controlName];
      const config = {
        [controlName]: this.isNestedGroup(control)
          ? this.addControls(control as Controls)
          : [
              control.value || '',
              { validators: control.validators || [], ...(control.updateOn && { updateOn: control.updateOn }) },
            ],
      };

      groupConfig = {
        ...groupConfig,
        ...config,
      };
    });

    return this.formBuilder.group(groupConfig);
  }

  private appendToFormGroup(controls: Controls, formGroup: FormGroup) {
    Object.keys(controls).forEach((controlName) => {
      const control = controls[controlName];

      if (this.isNestedGroup(control)) {
        // get sub form group
        const subGroup = formGroup.get(controlName) as FormGroup;

        // if subgroup does not exist, need to create new group
        if (!subGroup) {
          formGroup.addControl(controlName, this.addControls(control as Controls));

          return;
        }

        this.appendToFormGroup(control as Controls, subGroup);

        return;
      }

      // Mason 2022-06-17: The code below is a bit weird. The upgrade to Angular 14 + TypeScript 4.7.3 flagged the following line of code as illegal, because the type being passed to (deprecated!) formBuilder.control() is too wide. I know this is not a very good fix, but I do not want to change the functionality of the code in my current PR, because it is just about upgrading Nx, Angular, and Typescript. So I am trying to preserve existing behavior exactly, but make it pass the stricter checking. This code is kind of weird, therefore, but I don't think it will cause a new bug unless formBuilder.control() is lying about what types it can accept. (TS 4.7.3 gives an error because `control.value` might be a `Control` or `Controls`, which formBuilder.control() cannot handle.)
      //
      // Problematic code:
      //
      // formGroup.addControl(controlName, this.formBuilder.control(control.value || '', control.validators || []));

      const formControlValue = control.value;
      let reformedControlValue: string | number | boolean = '';
      if (
        typeof formControlValue === 'string' ||
        typeof formControlValue === 'number' ||
        typeof formControlValue === 'boolean'
      ) {
        reformedControlValue = formControlValue;
      }
      formGroup.addControl(controlName, this.formBuilder.control(reformedControlValue, control.validators || []));
    });
  }

  private isNestedGroup(control: Control) {
    return !(control.hasOwnProperty('value') || control.hasOwnProperty('validators'));
  }

  addControlsToForm(controls: Controls) {
    if (!this.formGroup) {
      this.formGroup = this.addControls(controls);
    } else {
      this.appendToFormGroup(controls, this.formGroup);
    }
  }

  removeControl(name: string) {
    if (!this.formGroup) return;

    // For case of nested groups, use like parent.child1.child2.control
    // and control will be removed
    const names = name.split('.');
    const totalNames = names.length;

    if (names.length === 1) {
      this.formGroup.removeControl(names[0]);

      return;
    }

    // get last formGroup
    // @ts-expect-error (legacy code incremental fix)
    const nestedFormGroup = this.getControl(names.slice(0, totalNames - 1).join('.')) as FormGroup;

    if (nestedFormGroup) {
      nestedFormGroup.removeControl(names[totalNames - 1]);
    }
  }

  getControl(name: string) {
    if (!this.formGroup) return;

    // If there is nested form groups, this allows to easily get nested control name
    // by just using . syntax like parent.child1.child2.control
    const names = name.split('.');

    // @ts-expect-error (legacy code incremental fix)
    const control = names.slice(1).reduce((_control: AbstractControl, curVal) => {
      return _control && _control.get(curVal);
    }, this.formGroup.get(names[0]));

    return control;
  }
}
