import { Component, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { EditEventKeyValuePairComponent } from '../edit-event-key-value-pair/edit-event-key-value-pair.component';
import { ComponentGeneratorFactoryService } from '../service/component-generator-factory.service';

const defaultValues = [
  {
    key: '',
    value: '',
  },
];

interface Options {
  label: string;
  controlName: string;
  validators: Validators;
  validationErrorMessageStringId?: string;
}

export interface KeyValuePairControlValues {
  key: string;
  value: string;
}

@Component({
  selector: 'app-key-value-pair',
  templateUrl: './edit-event-key-value-pair-container.component.html',
})
export class EditEventKeyValuePairContainerComponent {
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('keyValuePairContainer', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  // @ts-expect-error (legacy code incremental fix)
  @Input() keyOptions: Options;
  // @ts-expect-error (legacy code incremental fix)
  @Input() valueOptions: Options;
  // @ts-expect-error (legacy code incremental fix)
  @Input() parentFormNames: string;
  // @ts-expect-error (legacy code incremental fix)
  @Input() controlValues: KeyValuePairControlValues[];

  // This will be always increasing as user add new pairs,
  // even after user remove some pair, this won't decrease its count
  // which is to maintain uniqueness of control names.
  // I am happy if there can be better solution, one I know is to implement ngStore!!
  private controlCount = 0;

  private components: Array<ComponentRef<EditEventKeyValuePairComponent>> = [];

  constructor(private componentGeneratorFactoryService: ComponentGeneratorFactoryService) {}

  ngAfterViewInit(): void {
    if (!this.controlValues || (this.controlValues && !this.controlValues.length)) {
      this.controlValues = defaultValues;
    }
    this.controlValues.forEach((value, index) => {
      this.generateKeyValuePair(value);
      this.components[index].instance.addPairButtonAdded = true;
    });
    this.components[this.components.length - 1].instance.addPairButtonAdded = false;
  }

  generateKeyValuePair = (controlValue?: KeyValuePairControlValues) => {
    const component = this.componentGeneratorFactoryService.createComponent(
      EditEventKeyValuePairComponent,
      this.container,
      false
    );
    component.instance.generateKeyValuePair = this.generateKeyValuePair;
    component.instance.removeKeyValuePair = this.removeKeyValuePair;
    component.instance.viewReference = component;
    component.instance.componentIndex = ++this.controlCount;
    component.instance.parentFormNames = this.parentFormNames;
    component.instance.keyOptions = {
      ...this.keyOptions,
      controlName: `${this.keyOptions.controlName}-${component.instance.componentIndex}`,
    };
    component.instance.valueOptions = {
      ...this.valueOptions,
      controlName: `${this.valueOptions.controlName}-${component.instance.componentIndex}`,
    };
    // @ts-expect-error (legacy code incremental fix)
    component.instance.controlValues = controlValue;
    this.components.push(component);
    component.changeDetectorRef.detectChanges();

    return component;
  };

  removeKeyValuePair = (viewRef: ComponentRef<EditEventKeyValuePairComponent>) => {
    const index = this.container.indexOf(viewRef.hostView);
    this.container.remove(index);
    this.components.splice(index, 1);
  };
}
