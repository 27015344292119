import { LegacyAny } from '@soracom/shared/core';

import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UiInputComponent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiInput } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiSelectComponent } from '../../soracom-ui/ui-select/ui-select.component';
import { UiSelect } from '../../soracom-ui/ui-select/UiSelect';
import { UiTextareaComponent } from '../../soracom-ui/ui-textarea/ui-textarea.component';
import { UiTextArea } from '../../soracom-ui/ui-textarea/UiTextArea';
import { EditEventHandlerService } from './edit-event-handler.service';

interface CommonOptions {
  controlName: string;
  controlValue?: string | number | boolean;
  validators: Validators;
  parentFormNames?: string;
  callback?(componentRef: ComponentRef<any>): void;
}

interface CreateInputOptions extends Partial<UiInput>, CommonOptions {}

interface CreateSelectOptions<T> extends CommonOptions {
  labelId?: string;
  value?: T;
  options: T[];
  translationPrefix?: string;
  onValueChange?(uiSelect: UiSelect): void;
}

@Injectable()
export class ComponentGeneratorFactoryService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private editEventHandlerService: EditEventHandlerService,
    private translate: TranslateService
  ) {}

  createComponent<T>(componentClass: Type<T>, container: ViewContainerRef, clearContainer = true) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);

    if (clearContainer) {
      container.clear();
    }

    return container.createComponent(componentFactory);
  }

  createInputComponent = (options: CreateInputOptions, container: ViewContainerRef, append = false) => {
    const componentRef = this.createComponent<UiInputComponent>(UiInputComponent, container, !append);
    const content = new UiInput();
    // Also add control to form group
    this.addControlToFormGroup(options);
    // @ts-expect-error (legacy code incremental fix)
    content.formControl = this.editEventHandlerService.getControl(
      options.parentFormNames ? `${options.parentFormNames}.${options.controlName}` : options.controlName
    );

    Object.keys(options).forEach((option) => {
      (content as LegacyAny)[option] = (options as LegacyAny)[option];
    });
    content.layout = options.layout || 'vertical';
    content.testId = options.controlName;

    componentRef.instance.content = content;

    if (options.callback) {
      options.callback(componentRef);
    }

    return componentRef;
  };

  createTextAreaComponent = (options: CreateInputOptions, container: ViewContainerRef, append = false) => {
    const componentRef = this.createComponent<UiTextareaComponent>(UiTextareaComponent, container, !append);
    const content = new UiTextArea();
    this.addControlToFormGroup(options);
    // @ts-expect-error (legacy code incremental fix)
    content.formControl = this.editEventHandlerService.getControl(
      options.parentFormNames ? `${options.parentFormNames}.${options.controlName}` : options.controlName
    ) as FormControl;

    Object.keys(options).forEach((option) => {
      (content as LegacyAny)[option] = (options as LegacyAny)[option];
    });
    content.testId = options.controlName;

    componentRef.instance.content = content;

    if (options.callback) {
      options.callback(componentRef);
    }

    return componentRef;
  };

  createSelectComponent = <T>(options: CreateSelectOptions<T>, container: ViewContainerRef, append = false) => {
    const componentRef = this.createComponent(UiSelectComponent, container, !append);
    const content = new UiSelect<T>(options.options);

    if (options.translationPrefix) {
      content.optionFormatter = (option) => {
        return `${this.translate.instant(`${options.translationPrefix}.${options.controlName}.${option}`)}`;
      };
    }

    // @ts-expect-error (legacy code incremental fix)
    content.labelId = options.labelId;
    content.labelPosition = 'top';
    // Add control to form group.
    this.addControlToFormGroup(options);
    content.onValueChange = (uiSelect) => {
      this.onSelectChangeUpdateForm(options, uiSelect);
      if (options.onValueChange) {
        options.onValueChange(uiSelect);
      }
    };
    // @ts-expect-error (legacy code incremental fix)
    content.value = options.value;
    content.testId = options.controlName;
    // @ts-expect-error (legacy code incremental fix)
    componentRef.instance.content = content;

    if (options.callback) {
      options.callback(componentRef);
    }

    return componentRef;
  };

  addControlToFormGroup(options: CommonOptions) {
    const controls = {};
    const control = {
      [options.controlName]: {
        value: options.controlValue,
        validators: options.validators,
      },
    };

    if (!options.parentFormNames) {
      this.editEventHandlerService.addControlsToForm(control);
      return;
    }

    const lastObj = options.parentFormNames.split('.').reduce((accumulator: LegacyAny, curVal) => {
      accumulator[curVal] = {};
      return accumulator[curVal];
    }, controls);

    lastObj[options.controlName] = {
      ...control[options.controlName],
    };

    this.editEventHandlerService.addControlsToForm(controls);
  }

  onSelectChangeUpdateForm = <T>(options: CreateSelectOptions<T>, uiSelect: UiSelect) => {
    const control = this.editEventHandlerService.getControl(
      options.parentFormNames ? `${options.parentFormNames}.${options.controlName}` : options.controlName
    );
    // @ts-expect-error (legacy code incremental fix)
    control.setValue(uiSelect.value === 'null' ? null : uiSelect.value);
    // @ts-expect-error (legacy code incremental fix)
    control.markAsDirty();
  };
}
