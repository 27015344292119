import { LegacyAny } from '@soracom/shared/core';

import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { distinctUntilChanged } from 'rxjs/operators';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { CredentialsSet } from '../../../../app/shared/core/credentials_set';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiInput } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiSelect } from '../../soracom-ui/ui-select/UiSelect';
import { EventRuleOptions, EventTargetOptionIds } from '../edit-event-handler/edit-event-handler.component';
import { ComponentGeneratorFactoryService } from '../service/component-generator-factory.service';
import { EditEventHandlerService } from '../service/edit-event-handler.service';
import { KeyValue } from '../utils/data-processor';
import { ActionValuesComponentConfig } from './action-value-component-config';

export enum Actions {
  SendMailAction = 'SendMailAction',
  ChangeSpeedClassAction = 'ChangeSpeedClassAction',
  SendMailToOperatorAction = 'SendMailToOperatorAction',
  ActivationAction = 'ActivationAction',
  DeactivationAction = 'DeactivationAction',
  StandbyAction = 'StandbyAction',
  SuspendAction = 'SuspendAction',
  ExecuteWebRequestAction = 'ExecuteWebRequestAction',
  InvokeAWSLambdaAction = 'InvokeAWSLambdaAction',
  ImeiLockAction = 'ImeiLockAction',
}

export enum RunPriorityOptions {
  IMMEDIATELY = 'IMMEDIATELY',
  BEGINNING_OF_NEXT_MONTH = 'BEGINNING_OF_NEXT_MONTH',
  BEGINNING_OF_NEXT_DAY = 'BEGINNING_OF_NEXT_DAY',
  AFTER_ONE_DAY = 'AFTER_ONE_DAY',
  NEVER = 'NEVER',
}

export function getSpeedClassOptionIds(featureVisibilityService: FeatureVisibilityService) {
  // Mason 2022-05-11: FIXME: Note that for now we are leaving this FeatureVisibilityService-based checking in place, since it does work, but it would be better to ask SpeedClass directly what speed classes are avaialble. I have filed [sc-58237](https://app.shortcut.com/soracom/story/58237/make-speedclass-single-source-of-truth-for-special-speed-classes-and-remove-event-handler-s-own-checks) which will delete the two checks below, and instead defer to SpeedClass to make this decision.

  if (featureVisibilityService.isEnabled('eventHandlerXMobileSpeedClasses')) {
    return ['xm.minimum', 'xm.slow', 'xm.standard', 'xm.fast', 'xm.4xfast'];
  }

  const speedclasses = ['s1.minimum', 's1.slow', 's1.standard', 's1.fast', 's1.4xfast'];

  if (featureVisibilityService.isEnabled('eventHandler8xFastSpeedClass')) {
    speedclasses.push('s1.8xfast');
  }

  return speedclasses;
}

export enum HttpMethodOptions {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const contentTypeOptionIds = ['application/json'];

const defaultAvailableEmailPlaceholders = [
  'simId',
  'imsi',
  'operatorId',
  'date',
  'year',
  'month',
  'day',
  'tags',
  'coverage',
];

@Component({
  selector: 'edit-event-new-action',
  templateUrl: './edit-event-new-action.component.html',
})
export class EditEventNewActionComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  /**
   * Holds reference for actions template where actions will be added
   */
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('actionValueContainer', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  /**
   * Function which will be called on click of removeAction button
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() removeAction: (viewReference: ComponentRef<EditEventNewActionComponent>) => void;

  /**
   * Holds reference of itself when it was created in its parent component.
   * Which help us to remove this component on click of removeAction button.
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() viewReference: ComponentRef<EditEventNewActionComponent>;

  /**
   * Holds index number of component
   * so that we can use it to identify/manage controls within it
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() componentIndex: number;

  /** Default values for config */
  defaultEventActionConfig = {
    actionType: Actions.SendMailAction,
    to: '',
    title: '',
    message: '',
    executionDateTimeConst: RunPriorityOptions.IMMEDIATELY,
    httpMethod: HttpMethodOptions.GET,
    speedClass: getSpeedClassOptionIds(this.featureVisibilityService)[0],
    contentType: contentTypeOptionIds[0],
  };

  /**
   * When there is data from API, set initial values to components to be generated
   */
  @Input() eventActionConfig: KeyValue<string | KeyValue<string>> = this.defaultEventActionConfig;

  /** Will come from event list page */
  // @ts-expect-error (legacy code incremental fix)
  @Input() handlerId: string;

  /** Credentials data which is required for invokeAWSLambda action */
  // @ts-expect-error (legacy code incremental fix)
  @Input() awsCredentialsData: CredentialsSet[];

  /** Holds template reference of email placeholders information */
  @ViewChild('placeholderRichTipContent', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  placeholderRichTipContentTemplateRef: TemplateRef<any>;

  /** Available email placeholders and info */
  availableEmailPlaceholders = defaultAvailableEmailPlaceholders;

  /**
   * Button which removes added action
   */
  removeActionButton: UiButton = new UiButton();

  /**
   * Select box having different actions
   */
  // @ts-expect-error (legacy code incremental fix)
  actionSelect: UiSelect;

  /**
   * Select box for setting run priority for this event
   */
  // @ts-expect-error (legacy code incremental fix)
  runPriority: UiSelect;

  /**
   * Specifies in how many minutes (or whatever unit) this action will run
   */
  executionOffsetTime = new UiInput();

  /**
   * Each actions' component data structure.
   * Each action has different type of components which need to be generated dynamically as user chooses action
   */
  // @ts-expect-error (legacy code incremental fix)
  private actionValuesComponentConfig: ActionValuesComponentConfig;

  Actions = Actions;

  constructor(
    public translate: TranslateService,
    private editEventHandlerService: EditEventHandlerService,
    private changeDetectionRef: ChangeDetectorRef,
    private componentGeneratorFactoryService: ComponentGeneratorFactoryService,
    private featureVisibilityService: FeatureVisibilityService,
    private coverageTypeService: CoverageTypeService
  ) {}

  ngOnInit() {
    this.setupUiComponents();
    this.actionValuesComponentConfig = new ActionValuesComponentConfig({
      eventActionConfig: this.eventActionConfig,
      componentGeneratorFactoryService: this.componentGeneratorFactoryService,
      editEventHandlerService: this.editEventHandlerService,
      featureVisibilityService: this.featureVisibilityService,
      container: this.container,
      actionSelect: this.actionSelect,
      parentFormNames: this.getParentFormNames,
      handlerId: this.handlerId,
      awsCredentialsData: this.awsCredentialsData,
      placeholderRichTipContentTemplateRef: this.placeholderRichTipContentTemplateRef,
    });

    // set default value of action select after everything is setup, so that other components can be
    // generated based on this value and everything is ready to generate those components
    this.setActionSelectValue();

    this.editEventHandlerService.formGroup.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged((value1, value2) => {
          // change action select options only if hasImei prop was changed (bcoz form has other props too)
          // if condition met, subscriber wont be called!!
          return value1?.eventRule?.hasImei === value2?.eventRule?.hasImei;
        })
      )
      .subscribe(this.setActionSelectOptions);

    this.editEventHandlerService.formGroup.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged((value1, value2) => {
          // change action select options only if rule prop was changed (bcoz form has other props too)
          // if condition met, subscriber wont be called!!
          return value1?.eventRule?.rule === value2?.eventRule?.rule;
        })
      )
      .subscribe(this.setAvailableEmailActionPlaceholders);
  }

  private setupUiComponents() {
    this.setupSelects();
    this.setupInputs();
    this.setupButtons();
  }

  private setupSelects() {
    this.actionSelect = new UiSelect(this.getActionSelectOptions());
    this.actionSelect.labelId = 'editEventActions.action.label';
    this.actionSelect.labelPosition = 'top';
    this.actionSelect.optionFormatter = (option) => {
      return `${this.translate.instant(`editEventActions.action.${option}`)}`;
    };
    this.actionSelect.onValueChange = this.generateValueComponents;
    this.actionSelect.testId = 'actionSelect';

    this.runPriority = new UiSelect(Object.keys(RunPriorityOptions));
    this.runPriority.labelId = 'editEventActions.runPriority.label';
    this.runPriority.labelPosition = 'top';
    this.runPriority.optionFormatter = (option) => {
      return `${this.translate.instant(`editEventActions.runPriority.${option}`)}`;
    };
    this.runPriority.value = this.eventActionConfig.executionDateTimeConst;
    this.runPriority.onValueChange = this.onRunPriorityChange;
    this.runPriority.testId = 'runPriority';
  }

  private setupInputs() {
    this.executionOffsetTime.labelId = 'editEventActions.executionOffsetTime.label';
    this.executionOffsetTime.testId = 'executionOffsetTime';
  }

  private setupButtons() {
    this.removeActionButton.buttonStyle = 'icon';
    this.removeActionButton.iconName = 'icon-cancel';
    this.removeActionButton.classes = ['remove-action'];
    this.removeActionButton.onClick = this.onRemoveAction;
  }

  setActionSelectOptions = (formValue: LegacyAny) => {
    if (this.actionSelect) {
      if (formValue?.eventRule?.hasImei && !this.actionSelect.hasOption(Actions.ImeiLockAction)) {
        this.actionSelect.addValue(Actions.ImeiLockAction);
      } else if (!formValue?.eventRule?.hasImei) {
        this.actionSelect.removeValue(Actions.ImeiLockAction);
      }
      this.setActionSelectValue();
    }
  };

  setAvailableEmailActionPlaceholders = (formValue: LegacyAny) => {
    const rule = formValue.eventRule.rule;
    const isTargetOperator = formValue.eventTarget === EventTargetOptionIds.targetOperatorId;
    if (
      [
        EventRuleOptions.SubscriberStatusAttributeRule,
        EventRuleOptions.SimStatusAttributeRule,
        EventRuleOptions.SubscriberExpiredRule,
        EventRuleOptions.SimExpiredRule,
      ].includes(rule)
    ) {
      this.availableEmailPlaceholders = [...defaultAvailableEmailPlaceholders, 'status.oldStatus', 'status.newStatus'];
    } else if (
      [EventRuleOptions.SubscriberSpeedClassAttributeRule, EventRuleOptions.SimSpeedClassAttributeRule].includes(rule)
    ) {
      this.availableEmailPlaceholders = [
        ...defaultAvailableEmailPlaceholders,
        'speedClass.oldSpeedClass',
        'speedClass.newSpeedClass',
      ];
    } else if ([EventRuleOptions.SimSubscriptionStatusRule].includes(rule)) {
      this.availableEmailPlaceholders = [
        ...defaultAvailableEmailPlaceholders.filter((placeholder) => placeholder !== 'imsi'),
        'subscription.subscription',
        'subscription.otaStatus',
        'subscription.imsi',
        'subscription.primaryImsi',
      ];
    } else if (
      isTargetOperator &&
      [EventRuleOptions.DailyTotalTrafficRule, EventRuleOptions.MonthlyTotalTrafficRule].includes(rule)
    ) {
      this.availableEmailPlaceholders = defaultAvailableEmailPlaceholders.filter(
        (placeholder) => placeholder !== 'simId' && placeholder !== 'imsi'
      );
    } else {
      this.availableEmailPlaceholders = defaultAvailableEmailPlaceholders;
    }
  };

  getActionSelectOptions() {
    const actions: LegacyAny = [];
    const formValue = this.editEventHandlerService.formGroup.value;
    const sourceActions = formValue?.eventRule?.hasImei
      ? Object.keys(Actions).map((action) => action)
      : Object.keys(Actions).filter((action) => action !== Actions.ImeiLockAction);

    sourceActions.reduce((accum, currentVal) => {
      if (currentVal === Actions.ImeiLockAction) {
        if (this.featureVisibilityService.isEnabled('eventHandlerImeiLockAction')) {
          accum.push(currentVal);
        }
        return accum;
      }
      accum.push(currentVal);
      return accum;
    }, actions);

    return actions;
  }

  setActionSelectValue() {
    // In case the option previously selected is still in option list, don't make change in selectbox value
    // and that will prevent re-generating other dynamic components, so we can keep user settings
    if (this.actionSelect.value && this.actionSelect.hasOption(this.actionSelect.value)) {
      return;
    }
    const { actionType } = this.eventActionConfig;
    // there will be always one action in eventActionConfig object
    // in case value of action in config is not in actions array, assign default value
    this.actionSelect.value = this.actionSelect.hasOption(actionType)
      ? actionType
      : this.defaultEventActionConfig.actionType;
    this.changeDetectionRef.detectChanges();
  }

  getParentFormNames = () => {
    return `actions.${this.actionSelect.value}-${this.componentIndex}`;
  };

  getExecutionOffsetTimeFormControl() {
    return this.editEventHandlerService.getControl(`${this.getParentFormNames()}.executionOffsetMinutes`);
  }

  addStaticControlsToFormGroup() {
    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: 'executionDateTimeConst',
      controlValue: this.runPriority.value,
      validators: [],
      parentFormNames: this.getParentFormNames(),
    });

    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: 'executionOffsetMinutes',
      controlValue: '',
      validators: [],
      parentFormNames: this.getParentFormNames(),
    });
    // @ts-expect-error (legacy code incremental fix)
    this.executionOffsetTime.formControl = this.getExecutionOffsetTimeFormControl();

    // reset value too, in case data coming from API after selecting some event from list,
    // if user changes action then reset. If after that change, again user come back to
    // original action came from API, then set value of this below componets also from API.
    if (this.actionSelect.value === this.eventActionConfig.actionType) {
      this.runPriority.value = this.eventActionConfig.executionDateTimeConst;
      this.executionOffsetTime.formControl.setValue(this.eventActionConfig.executionOffsetMinutes);
    } else {
      this.runPriority.value = RunPriorityOptions.IMMEDIATELY;
      this.executionOffsetTime.formControl.setValue('');
    }
  }

  onRemoveAction = () => {
    // Remove form controls of removed actions from formGroup
    this.removeFormControlsFromGroup(this.actionSelect.value);

    this.removeAction(this.viewReference);
  };

  removeFormControlsFromGroup = (action: string) => {
    // Remove previous selected action's formcontrols from formGroup
    if (this.editEventHandlerService) {
      this.editEventHandlerService.removeControl(`actions.${action}-${this.componentIndex}`);
    }
  };

  onRunPriorityChange = () => {
    const formControl = this.editEventHandlerService.getControl(
      `actions.${this.actionSelect.value}-${this.componentIndex}.executionDateTimeConst`
    );
    // @ts-expect-error (legacy code incremental fix)
    formControl.setValue(this.runPriority.value);
    // @ts-expect-error (legacy code incremental fix)
    formControl.markAsDirty();

    this.executionOffsetTime.formControl.setValue(
      this.runPriority.value === this.eventActionConfig.executionDateTimeConst
        ? this.eventActionConfig.executionOffsetMinutes
        : ''
    );
    this.executionOffsetTime.status = this.runPriority.value === RunPriorityOptions.NEVER ? 'readonly' : 'default';
  };

  showStandbyActionMessage2() {
    return this.coverageTypeService.isJapan();
  }

  /**
   * On action select change, generate components for selected action
   */
  generateValueComponents = () => {
    const selectedActionId = this.actionSelect.value;
    const action = this.actionValuesComponentConfig.actionComponentStructure.find(
      (curAction) => curAction.actionId === selectedActionId
    );

    // there can be no action value component, so just clear previous value
    // @ts-expect-error (legacy code incremental fix)
    if (!action.actionValues.length) {
      this.container.clear();
    }

    if (this.actionSelect.previousValue) {
      this.removeFormControlsFromGroup(this.actionSelect.previousValue);
    }

    // update values of static controls in formGroup
    this.addStaticControlsToFormGroup();

    this.actionValuesComponentConfig.actionComponentsRefCache[this.actionSelect.value] = [];

    // @ts-expect-error (legacy code incremental fix)
    action.actionValues.forEach((actionValue) => {
      setTimeout(() => {
        const componentRef = actionValue.generateComponent();
        // Store ref of component in cache, so we can play with generated components later
        this.actionValuesComponentConfig.actionComponentsRefCache[this.actionSelect.value].push(componentRef);
        // As we are changing content after adding component to view, it causes exception and does not update view
        componentRef.changeDetectorRef.detectChanges();
      }, 0);
    });
  };
}
