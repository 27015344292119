<section class="ds-card __counter" [attr.data-testid]="getParentFormNames()">
  <div class="__close">
    <ui-button [content]="removeActionButton"></ui-button>
  </div>
  <div class="ds-rows">
    <div class="ds-span--5">
      <ui-select [content]="actionSelect"></ui-select>
      <div
        class="ds-text--small"
        *ngIf="actionSelect.value === Actions.StandbyAction"
        data-testid="standby-action-message"
      >
        <div>
          <i class="ds-icon--icon-warning ds-icon--tiny"></i>
          <span [innerHTML]="'editEventActions.actionInfo.standbyActionInfo1' | translate"></span>
        </div>
        <div *ngIf="showStandbyActionMessage2()">
          <i class="ds-icon--icon-warning ds-icon--tiny"></i>
          <span [innerHTML]="'editEventActions.actionInfo.standbyActionInfo2' | translate"></span>
        </div>
      </div>
      <div
        class="ds-text--small"
        *ngIf="actionSelect.value === Actions.SuspendAction"
        data-testid="suspend-action-message"
      >
        <div>
          <i class="ds-icon--icon-warning ds-icon--tiny"></i>
          <span [innerHTML]="'editEventActions.actionInfo.suspendActionInfo1' | translate"></span>
        </div>
      </div>
    </div>
    <div class="ds-span--4">
      <ui-select [content]="runPriority"></ui-select>
    </div>
    <div class="ds-span--3">
      <ui-input [content]="executionOffsetTime"></ui-input>
    </div>
    <div class="ds-span--12 ds-rows">
      <ng-template #actionValueContainer></ng-template>
    </div>
  </div>
</section>

<ng-template #placeholderRichTipContent>
  <div class="ds-text">{{ 'editEventActions.availablePlaceholders.aboutPlaceholders' | translate }}</div>
  <table class="ds-datatable --condensed">
    <thead>
      <tr>
        <th>{{ 'editEventActions.availablePlaceholders.placeholderTable.column.placeholders' | translate }}</th>
        <th>{{ 'editEventActions.availablePlaceholders.placeholderTable.column.description' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of availableEmailPlaceholders">
        <td
          [innerHTML]="'editEventActions.availablePlaceholders.placeholderTable.placeholder.' + item | translate"
        ></td>
        <td
          [innerHTML]="
            'editEventActions.availablePlaceholders.placeholderTable.placeholderDescription.' + item | translate
          "
        ></td>
      </tr>
    </tbody>
  </table>
</ng-template>
