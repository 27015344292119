import { FormGroup } from '@angular/forms';

export function checkForValidityBeforeUpdate(formGroup: FormGroup) {
  const controls = formGroup.controls;
  Object.keys(controls).forEach((key) => {
    const control = formGroup.get(key);

    if ((control as FormGroup).controls) {
      checkForValidityBeforeUpdate(control as FormGroup);
      return;
    }

    // @ts-expect-error (legacy code incremental fix)
    control.markAsDirty();
    // @ts-expect-error (legacy code incremental fix)
    control.markAsTouched();
  });
}

export function scrollToFirstInvalidElement(formElement: HTMLElement) {
  const invalidControl = formElement.querySelector('.ng-invalid');

  if (invalidControl) {
    scrollToElement(invalidControl);
  } else {
    // first element is global form and we are looking for first nested form
    const invalidControls = formElement.querySelectorAll('form .ng-invalid');
    if (invalidControls && invalidControls.length) {
      scrollToElement(invalidControls[0]);
    }
  }
}

function scrollToElement(element: Element) {
  if (element) {
    const position = element.getBoundingClientRect() as DOMRect;
    const distance = window.pageYOffset - Math.abs(position.y) + Math.abs(position.bottom) + 130;

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: position.top + window.scrollY - 150,
    });

    setTimeout(() => {
      (element as HTMLElement).focus();
      (element as HTMLElement).blur();
      (element as HTMLElement).focus();
    }, distance);
  }
}
