import { Component, ComponentRef, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiInput } from '@soracom/shared-ng/soracom-ui-legacy';
import { KeyValuePairControlValues } from '../edit-event-key-value-pair-container/edit-event-key-value-pair-container.component';
import { ComponentGeneratorFactoryService } from '../service/component-generator-factory.service';
import { EditEventHandlerService } from '../service/edit-event-handler.service';

interface Options {
  controlName: string;
  validators: Validators;
  validationErrorMessageStringId?: string;
}

@Component({
  selector: 'app-key-value-pair',
  templateUrl: './edit-event-key-value-pair.component.html',
})
export class EditEventKeyValuePairComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() generateKeyValuePair: () => void;
  // @ts-expect-error (legacy code incremental fix)
  @Input() removeKeyValuePair: (viewReference: ComponentRef<EditEventKeyValuePairComponent>) => void;
  // @ts-expect-error (legacy code incremental fix)
  @Input() viewReference: ComponentRef<EditEventKeyValuePairComponent>;
  // @ts-expect-error (legacy code incremental fix)
  @Input() keyOptions: Options;
  // @ts-expect-error (legacy code incremental fix)
  @Input() valueOptions: Options;
  // @ts-expect-error (legacy code incremental fix)
  @Input() componentIndex: number;
  // @ts-expect-error (legacy code incremental fix)
  @Input() parentFormNames: string;
  // @ts-expect-error (legacy code incremental fix)
  @Input() controlValues: KeyValuePairControlValues;
  @Input() addPairButtonAdded = false;

  addPairButton: UiButton = new UiButton();
  removePairButton: UiButton = new UiButton();
  keyInput: UiInput = new UiInput();
  valueInput: UiInput = new UiInput();

  constructor(
    private componentGeneratorFactoryService: ComponentGeneratorFactoryService,
    private editEventHandlerService: EditEventHandlerService
  ) {}

  ngOnInit() {
    this.addPairButton.buttonStyle = 'icon';
    this.addPairButton.iconName = 'icon-plus';
    this.addPairButton.onClick = this.onAddPairClick;

    this.removePairButton.buttonStyle = 'icon';
    this.removePairButton.iconName = 'icon-minus';
    this.removePairButton.onClick = this.onRemovePairClick;

    this.setupInput();
  }

  setupInput() {
    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: this.keyOptions.controlName,
      controlValue: (this.controlValues && this.controlValues.key) || '',
      validators: this.keyOptions.validators,
      parentFormNames: this.parentFormNames,
    });
    // @ts-expect-error (legacy code incremental fix)
    this.keyInput.formControl = this.editEventHandlerService.getControl(
      `${this.parentFormNames}.${this.keyOptions.controlName}`
    );
    // @ts-expect-error (legacy code incremental fix)
    this.keyInput.validationErrorMessageStringId = this.keyOptions.validationErrorMessageStringId;
    this.keyInput.testId = this.keyOptions.controlName;

    this.componentGeneratorFactoryService.addControlToFormGroup({
      controlName: this.valueOptions.controlName,
      controlValue: (this.controlValues && this.controlValues.value) || '',
      validators: this.valueOptions.validators,
      parentFormNames: this.parentFormNames,
    });
    // @ts-expect-error (legacy code incremental fix)
    this.valueInput.formControl = this.editEventHandlerService.getControl(
      `${this.parentFormNames}.${this.valueOptions.controlName}`
    );
    // @ts-expect-error (legacy code incremental fix)
    this.valueInput.validationErrorMessageStringId = this.valueOptions.validationErrorMessageStringId;
    this.valueInput.testId = this.valueOptions.controlName;
  }

  onAddPairClick = () => {
    setTimeout(() => {
      this.generateKeyValuePair();
    }, 0);
    this.addPairButtonAdded = true;
  };

  onRemovePairClick = () => {
    // Also remove control from Form
    this.editEventHandlerService.removeControl(`${this.parentFormNames}.${this.keyOptions.controlName}`);
    this.editEventHandlerService.removeControl(`${this.parentFormNames}.${this.valueOptions.controlName}`);
    setTimeout(() => {
      this.removeKeyValuePair(this.viewReference);
    }, 0);
  };
}
