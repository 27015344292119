import { LegacyAny } from '@soracom/shared/core';

import { ComponentRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';

import { CredentialsSet } from '../../../../app/shared/core/credentials_set';
import { UiInputComponent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiRichTip } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiSelect } from '../../soracom-ui/ui-select/UiSelect';
import { EditEventKeyValuePairContainerComponent } from '../edit-event-key-value-pair-container/edit-event-key-value-pair-container.component';
import { ComponentGeneratorFactoryService } from '../service/component-generator-factory.service';
import { EditEventHandlerService } from '../service/edit-event-handler.service';
import { convertPairValuesToControlValues, KeyValue } from '../utils/data-processor';
import {
  Actions,
  contentTypeOptionIds,
  getSpeedClassOptionIds,
  HttpMethodOptions,
} from './edit-event-new-action.component';

interface Options {
  eventActionConfig: KeyValue<string | KeyValue<string>>;
  componentGeneratorFactoryService: ComponentGeneratorFactoryService;
  editEventHandlerService: EditEventHandlerService;
  featureVisibilityService: FeatureVisibilityService;
  container: ViewContainerRef;
  actionSelect: UiSelect;
  handlerId: string;
  awsCredentialsData: CredentialsSet[];
  /** making it function because actionSelect is not initialized when this class instantiated in constructor */
  parentFormNames?(): string;
  placeholderRichTipContentTemplateRef: TemplateRef<any>;
}

export interface ActionComponentStructure {
  actionId: keyof typeof Actions;
  actionValues: Array<{
    generateComponent: () => ComponentRef<any>;
  }>;
}

export class ActionValuesComponentConfig {
  private _actionComponentStructure: ActionComponentStructure[];

  /**
   * Cache to hold added action components
   */
  actionComponentsRefCache: { [key: string]: Array<ComponentRef<any>> } = {};

  constructor(private options: Options) {
    this._actionComponentStructure = [
      {
        actionId: Actions.SendMailAction,
        actionValues: this.emailActionValues(),
      },
      {
        actionId: Actions.SendMailToOperatorAction,
        actionValues: this.emailActionValues().slice(1), // "to" is not needed
      },
      {
        actionId: Actions.ChangeSpeedClassAction,
        actionValues: this.speedClassActionValues(),
      },
      {
        actionId: Actions.ActivationAction,
        actionValues: [],
      },
      {
        actionId: Actions.DeactivationAction,
        actionValues: [],
      },
      {
        actionId: Actions.StandbyAction,
        actionValues: [],
      },
      {
        actionId: Actions.SuspendAction,
        actionValues: [],
      },
      {
        actionId: Actions.ExecuteWebRequestAction,
        actionValues: this.executeWebRequestActionValues(),
      },
      {
        actionId: Actions.InvokeAWSLambdaAction,
        actionValues: this.invokeAWSLambdaActionValues(),
      },
    ];

    // ImeiLock action is only for some operators
    if (this.options.featureVisibilityService.isEnabled('eventHandlerImeiLockAction')) {
      this._actionComponentStructure.push({
        actionId: Actions.ImeiLockAction,
        actionValues: [],
      });
    }
  }

  get actionComponentStructure() {
    return this._actionComponentStructure;
  }

  private emailActionValues() {
    return [
      {
        generateComponent: () =>
          this.options.componentGeneratorFactoryService.createInputComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.emailTo',
              controlName: 'to',
              controlValue:
                this.options.actionSelect.value === this.options.eventActionConfig.actionType
                  ? (this.options.eventActionConfig.to as string) || ''
                  : '',
              validators: [Validators.required, Validators.email],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
            },
            this.options.container
          ),
      },
      {
        generateComponent: () => {
          const placeholderRichTip = new UiRichTip('editEventActions.availablePlaceholders.richtipLabel');
          placeholderRichTip.type = 'text';
          placeholderRichTip.templateRef = this.options.placeholderRichTipContentTemplateRef;
          placeholderRichTip.position = 'bottom-right';

          return this.options.componentGeneratorFactoryService.createInputComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.emailTitle',
              controlName: 'title',
              controlValue:
                this.options.actionSelect.value === this.options.eventActionConfig.actionType
                  ? (this.options.eventActionConfig.title as string) || ''
                  : '',
              validators: [Validators.required],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
              richtip: placeholderRichTip,
              richtipPosition: 'end',
            },
            this.options.container,
            this.options.actionSelect.value === Actions.SendMailAction
          );
        },
      },
      {
        generateComponent: () =>
          this.options.componentGeneratorFactoryService.createTextAreaComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.emailMessage',
              controlName: 'message',
              controlValue:
                this.options.actionSelect.value === this.options.eventActionConfig.actionType
                  ? (this.options.eventActionConfig.message as string) || ''
                  : '',
              validators: [Validators.required],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
            },
            this.options.container,
            true
          ),
      },
    ];
  }

  private speedClassActionValues() {
    return [
      {
        generateComponent: () => {
          return this.options.componentGeneratorFactoryService.createSelectComponent(
            {
              options: getSpeedClassOptionIds(this.options.featureVisibilityService),
              labelId: 'editEventActions.actionValueComponentLabel.speedClass',
              controlName: 'speedClass',
              translationPrefix: 'editEventActions',
              value: this.options.eventActionConfig.speedClass,
              validators: [],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
            },
            this.options.container
          );
        },
      },
    ];
  }

  private executeWebRequestActionValues() {
    return [
      {
        generateComponent: () => {
          return this.options.componentGeneratorFactoryService.createInputComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.webRequestUrl',
              controlName: 'url',
              controlValue: this.options.eventActionConfig.url as string,
              validators: [Validators.required],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
            },
            this.options.container
          );
        },
      },
      {
        generateComponent: () => {
          return this.options.componentGeneratorFactoryService.createSelectComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.webRequestHttpMethod',
              controlName: 'httpMethod',
              options: Object.keys(HttpMethodOptions),
              translationPrefix: 'editEventActions',
              value: this.options.eventActionConfig.httpMethod,
              validators: [],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              onValueChange: this.onHttpMethodChange,
            },
            this.options.container,
            true
          );
        },
      },
      {
        generateComponent: () => {
          return this.options.componentGeneratorFactoryService.createSelectComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.webRequestContentType',
              controlName: 'contentType',
              options: contentTypeOptionIds,
              translationPrefix: 'editEventActions',
              value: this.options.eventActionConfig.contentType,
              validators: [],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
            },
            this.options.container,
            true
          );
        },
      },
      {
        generateComponent: () => {
          const componentRef = this.options.componentGeneratorFactoryService.createComponent(
            EditEventKeyValuePairContainerComponent,
            this.options.container,
            false
          );
          // @ts-expect-error (legacy code incremental fix)
          componentRef.instance.parentFormNames = `${this.options.parentFormNames()}.headers`;
          componentRef.instance.keyOptions = {
            label: 'editEventActions.webRequestHeaders.keyLabel',
            controlName: 'headerName',
            validators: [],
          };
          componentRef.instance.valueOptions = {
            label: 'editEventActions.webRequestHeaders.valueLabel',
            controlName: 'headerValue',
            validators: [],
          };
          componentRef.instance.controlValues = convertPairValuesToControlValues(
            this.options.eventActionConfig.headers as KeyValue<string>,
            'header'
          );

          return componentRef;
        },
      },
      {
        generateComponent: () => {
          return this.options.componentGeneratorFactoryService.createInputComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.webRequestBody',
              controlName: 'body',
              controlValue: this.options.eventActionConfig.body as string,
              validators: [Validators.required],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
              callback: (componentRef) => {
                // check httpMethod select value
                const httpMethodComponentRef = this.actionComponentsRefCache[this.options.actionSelect.value][1];
                this.setWebRequestBodyInputStatus(componentRef, httpMethodComponentRef.instance.content);
              },
            },
            this.options.container,
            true
          );
        },
      },
    ];
  }

  private invokeAWSLambdaActionValues() {
    const components: ActionComponentStructure['actionValues'] = [
      {
        generateComponent: () => {
          return this.options.componentGeneratorFactoryService.createInputComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.invokeAWSLambdaEndpoint',
              controlName: 'endpoint',
              controlValue: this.options.eventActionConfig.endpoint as string,
              validators: [Validators.required],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
            },
            this.options.container
          );
        },
      },
      {
        generateComponent: () => {
          return this.options.componentGeneratorFactoryService.createInputComponent(
            {
              labelId: 'editEventActions.actionValueComponentLabel.invokeAWSLambdaFunctionName',
              controlName: 'functionName',
              controlValue: this.options.eventActionConfig.functionName as string,
              validators: [Validators.required],
              // @ts-expect-error (legacy code incremental fix)
              parentFormNames: this.options.parentFormNames(),
              validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
            },
            this.options.container,
            true
          );
        },
      },
      {
        generateComponent: () => {
          const componentRef = this.options.componentGeneratorFactoryService.createComponent(
            EditEventKeyValuePairContainerComponent,
            this.options.container,
            false
          );
          // @ts-expect-error (legacy code incremental fix)
          componentRef.instance.parentFormNames = `${this.options.parentFormNames()}.params`;
          componentRef.instance.keyOptions = {
            label: 'editEventActions.lambdaParams.keyLabel',
            controlName: 'paramName',
            validators: [Validators.pattern(/^parameter[1-5]$/)],
            validationErrorMessageStringId: 'editEventActions.inpuValidationMessage.invokeAWSLambdaParams',
          };
          componentRef.instance.valueOptions = {
            label: 'editEventActions.lambdaParams.valueLabel',
            controlName: 'paramValue',
            validators: [],
          };
          componentRef.instance.controlValues = convertPairValuesToControlValues(
            this.options.eventActionConfig.params as KeyValue<string>,
            'param'
          );

          return componentRef;
        },
      },
    ];

    // As of 2020/10/02, we will remove access key and secret access key inputs from user and provide
    // credential selector. But for backward compatibility, we still need to support them for existing event
    // handlers. So if there is handlerId, it means it's existing event handler and if there is no
    // credentialsId in actionConfig data, that means it is old legacy handler with access key
    if (
      this.options.handlerId &&
      (this.options.eventActionConfig.accessKey || this.options.eventActionConfig.secretAccessKey)
    ) {
      components.splice(
        2,
        0,
        ...[
          {
            generateComponent: () => {
              return this.options.componentGeneratorFactoryService.createInputComponent(
                {
                  labelId: 'editEventActions.actionValueComponentLabel.invokeAWSLambdaAccessKey',
                  controlName: 'accessKey',
                  controlValue: this.options.eventActionConfig.accessKey as string,
                  validators: [Validators.required],
                  // @ts-expect-error (legacy code incremental fix)
                  parentFormNames: this.options.parentFormNames(),
                  validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
                },
                this.options.container,
                true
              );
            },
          },
          {
            generateComponent: () => {
              return this.options.componentGeneratorFactoryService.createInputComponent(
                {
                  labelId: 'editEventActions.actionValueComponentLabel.invokeAWSLambdaSecretAccessKey',
                  controlName: 'secretAccessKey',
                  controlValue: this.options.eventActionConfig.secretAccessKey as string,
                  validators: [Validators.required],
                  type: 'password',
                  enableViewPasswordToggle: true,
                  viewPasswordToggleButtonLabel: 'editEventActions.viewSecretKeyToggle',
                  // @ts-expect-error (legacy code incremental fix)
                  parentFormNames: this.options.parentFormNames(),
                  validationErrorMessageStringId: 'editEventActions.inpuValidationMessage',
                },
                this.options.container,
                true
              );
            },
          },
        ]
      );
    } else {
      components.splice(
        2,
        0,
        ...[
          {
            generateComponent: () => {
              const awsCredentialsData = this.options.awsCredentialsData;
              const options = awsCredentialsData ? awsCredentialsData.map((datum) => datum.credentialsId) : [];

              return this.options.componentGeneratorFactoryService.createSelectComponent(
                {
                  labelId: 'editEventActions.actionValueComponentLabel.invokeAWSLambdaCredentialsId',
                  controlName: 'credentialsId',
                  options,
                  value: this.options.eventActionConfig.credentialsId,
                  validators: [],
                  // @ts-expect-error (legacy code incremental fix)
                  parentFormNames: this.options.parentFormNames(),
                },
                this.options.container,
                true
              );
            },
          },
        ]
      );
    }

    return components;
  }

  private onHttpMethodChange = (uiSelect: LegacyAny) => {
    const bodyInputRef = this.actionComponentsRefCache[Actions.ExecuteWebRequestAction][4];
    this.setWebRequestBodyInputStatus(bodyInputRef, uiSelect);
  };

  private setWebRequestBodyInputStatus = (inputComponentRef: ComponentRef<UiInputComponent>, uiSelect: UiSelect) => {
    // @ts-expect-error (legacy code incremental fix)
    const formControl = this.options.editEventHandlerService.getControl(`${this.options.parentFormNames()}.body`);

    if (inputComponentRef) {
      // If method is GET, disable "body" input
      if (uiSelect.value === HttpMethodOptions.GET || uiSelect.value === HttpMethodOptions.DELETE) {
        inputComponentRef.instance.content.status = 'readonly';
        // Also update/reset value in FormGroup
        // @ts-expect-error (legacy code incremental fix)
        formControl.disable();
      } else {
        inputComponentRef.instance.content.status = 'default';
        // @ts-expect-error (legacy code incremental fix)
        formControl.enable();
      }
    }
  };
}
